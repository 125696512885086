.button {
  @include reset-button;
  z-index: 1;
  position: relative;
  display: inline-flex;
  height: 50px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.2);

  .icon {
    width: 23px;
    height: 23px;
  }

  &.button-star-o:hover {
    .icon.-star-o {
      fill: map-get($colors, bondi-blue);
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__caption {
    margin: 0 auto;
    font-size: 1.6rem;
    color: map-get($colors, white);
  }

  &.-wide {
    padding: 0 35px;

    .button__caption {
      font-size: 1.6rem;
    }
  }

  &.-small {
    padding: 0 13px;
  }

  &.-book {
    float: right;
    margin-bottom: 75px;
    padding: 0 30px;
  }

  &.-modal {
    width: 70px;
    height: 40px;
    box-shadow: inset 0 0 0 1px map-get($colors, white);

    .button__caption {
      width: 100%;
      display: block;
      font-size: 1.6rem;
      font-weight: 700;
      text-align: center;
      line-height: 4rem;
      pointer-events: none;
    }
  }

  &.-green {
    background-color: map-get($colors, green);
    transition: background-color 0.15s ease;

    &:hover {
      background-color: map-get($colors, ecalyptus);
    }

    &:active {
      background-color: map-get($colors, jewel);
    }
  }

  &.-gray {
    background-color: map-get($colors, aluminium);
    transition: background-color 0.15s ease;

    .icon {
      fill: white;
    }

    &:hover {
      background-color: map-get($colors, boulder);
    }

    &:active {
      background-color: map-get($colors, storm-dust);
    }
  }

  &.-book {
    @include breakpoint(mobile) {
      display: block;
      margin: 0 auto 76px;
      float: none;
      width: 186px;
      box-shadow: none;

      .button__caption {
        width: 100%;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
