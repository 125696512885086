.card {
  height: 366px;

  &__link,
  &__article {
    display: block;
    height: 100%;
  }

  &__link {
    text-decoration: none;
  }

  &__article {
    box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.2);
  }

  &__thumbnail {
    height: 260px;
    background-size: cover;
    background-position: center top;

    .svg-thumbnail {
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    padding: 11px;
    height: 106px;
  }

  &__avatar {
    margin-right: 7px;
    float: left;
    width: 33px;
    height: 33px;
    background-size: cover;
    background-position: center center;
  }

  &__title {
    position: relative;
    top: -1px;
    float: left;
    clear: right;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: map-get($colors, bondi-blue);
  }

  &__time,
  &__paragraph {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: map-get($colors, aluminium);
  }

  &__time {
    position: relative;
    top: -1px;
    float: left;
    clear: right;
    font-style: italic;
  }

  &__paragraph {
    padding-top: 7px;
    float: left;
    display: block;
  }

  &__isolated {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 10px 30px;

    figure {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      mix-blend-mode: multiply;
    }

    div {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.25;
      background-image: url(../images/gradient-card.jpg);
      background-size: cover;

      @supports (display: block) {
        z-index: -1;
        opacity: 1;
      }
    }
  }

  &.-isolated {
    .card__thumbnail {
      z-index: 1;
      position: relative;
    }
  }

  &.-home {
    flex-flow: column wrap;
    display: flex;
    height: auto;
    min-height: 366px;

    .card__article {
      flex-grow: 1;
      text-decoration: none;
    }

    .card__body {
      height: auto;

      &::after {
        display: table;
        content: '';
        clear: both;
      }
    }

    .card__title {
      width: calc(100% - 40px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .card__thumbnail {
      position: relative;
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      display: inline-block;
      transform: translate(-50%, -50%) scale(1.4);

      & span {
        display: inline-block;
        margin: 0 3px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: map-get($colors, white);

        @include breakpoint(tablet) {
          margin: 0 4px;
          width: 8px;
          height: 8px;
        }

        &:nth-child(1) {
          animation: loader 1.4s infinite ease;
        }

        &:nth-child(2) {
          animation: loader 1.4s infinite ease 160ms;
        }

        &:nth-child(3) {
          animation: loader 1.4s infinite ease 320ms;
        }
      }
    }
  }

  &.-placeholder {
    .card__thumbnail,
    .card__avatar,
    .card__title,
    .card__time {
      background-color: map-get($colors, gallery);
    }

    .card__title {
      height: 1.8rem;
    }

    .card__time {
      position: relative;
      top: 4px;
      height: 10px;
      width: 90px;
    }
  }

  &.-products,
  &.-training,
  &.-contact {
    .card__thumbnail {
      position: relative;

      &::after {
        position: absolute;
        right: -1px;
        bottom: -5%;
        left: 0;
        content: '';
        width: 100%;
        height: 10%;
        border-left: 1px solid map-get($colors, athens-gray);
        border-right: 1px solid map-get($colors, athens-gray);
        background-color: map-get($colors, white);
        transform: skewY(-5.5deg) translate3d(0, 0, 0);
        box-shadow:
          1px 0 0 0 map-get($colors, white),
          inset 1px 0 0 0 map-get($colors, white);
      }
    }

    @media (min-width: 560px) and (max-width: 960px) {
      width: 40%;
    }

    @media (min-width: 790px) and (max-width: 960px) {
      .card__thumbnail::after {
        bottom: -32px !important;
        height: 50px !important;
      }
    }

    @media (min-width: 460px) and (max-width: 560px) {
      .card__thumbnail::after {
        bottom: -25px !important;
        height: 50px !important;
      }
    }

    @media (min-width: 320px) and (max-width: 460px) {
      .card__thumbnail::after {
        bottom: -30px !important;
        height: 50px !important;
      }
    }
  }

  &.-products {
    margin-bottom: 41px;
    display: flex;
    float: left;
    height: auto;

    .card {
      &__link {
        width: 100%;
      }

      &__thumbnail {
        z-index: -1;
        height: 262px;
      }

      &__article {
        box-shadow: inset 0 0 0 1px map-get($colors, athens-gray);
      }

      &__title {
        float: none;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        color: map-get($colors, fiord);
      }

      &__description {
        padding-top: 9px;
        width: 100%;

        p {
          max-width: none;
          font-size: 1.4rem;
          line-height: 2rem;
          color: map-get($colors, fiord);
        }
      }

      &__body {
        position: relative;
        padding: 17px 18px;
        height: auto;
      }

      &__star {
        position: absolute;
        top: -8px;
        right: 14px;

        .icon {
          width: 16px;
          height: 16px;
          fill: map-get($colors, fiord);

          &.-star-o {
            transition: fill 0.15s ease-out;
          }
        }

        @include breakpoint(tablet up) {
          &:hover {
            .icon.-star-o {
              fill: map-get($colors, bondi-blue);
            }
          }
        }

        @include breakpoint(tablet) {
          &:active {
            .icon.-star-o {
              fill: map-get($colors, bondi-blue);
            }
          }
        }
      }

      &__avatar,
      &__time,
      &__paragraph {
        display: none;
      }
    }

    @media (min-width: 560px) and (max-width: 960px) {
      padding-right: 2px !important;
      padding-left: 2px !important;
      width: calc(50% - 20px);
    }

    @include breakpoint(mobile) {
      padding-right: 2px !important;
      padding-left: 2px !important;

      .card__thumbnail::after {
        bottom: -10%;
        height: 20%;
      }

      .card__link {
        z-index: 1;
        position: relative;
      }

      .card__button {
        position: absolute;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 40px;
        height: 40px;
        background-color: map-get($colors, athens-gray);
        pointer-events: none;

        .icon {
          fill: map-get($colors, white);
        }
      }
    }
  }

  &.-training {
    height: auto;
    margin-bottom: 75px;

    .card__link,
    .card__article {
      height: auto;
    }

    .card__article {
      box-shadow: none;
    }

    .card__avatar,
    .card__time {
      display: none;
    }

    .card__thumbnail {
      align-items: center;
      justify-content: center;
      display: flex;
      height: 200px;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-image: radial-gradient(transparent, rgba(map-get($colors, black), 0.5) 150%);
        background-color: map-get($colors, bondi-blue);
        opacity: 0.7;
      }

      &::after {
        bottom: -8.5%;
        height: 15%;
        border: none;
      }

      .icon {
        z-index: 1;
        position: relative;
        width: 130px;
        height: 130px;
        fill: map-get($colors, white);
      }
    }

    .card__body {
      padding-bottom: 29px;
      height: auto;
    }

    .card__title {
      margin-bottom: 8px;
      display: block;
      width: 100%;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.2rem;
      text-transform: none;
      text-align: center;
      color: map-get($colors, fiord);
    }

    .card__paragraph,
    .card__description {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    .card__paragraph {
      margin-bottom: 1.8rem;
      padding: 0 5px;
      float: none;
      color: map-get($colors, fiord);
    }

    .card__description {
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: underline;
      color: map-get($colors, bondi-blue);
      transition: color 0.15s ease;

      &:hover {
        color: map-get($colors, blue-lagoon);
      }

      &:active {
        color: map-get($colors, blue-stone);
      }
    }

    &.-red {
      .card__thumbnail {
        &::before {
          background-color: map-get($colors, carmine-pink);
        }
      }
    }

    &.-green {
      .card__thumbnail {
        &::before {
          background-color: map-get($colors, green);
        }
      }
    }

    &.-yellow {
      .card__thumbnail {
        &::before {
          background-color: map-get($colors, yellow-sea);
        }
      }
    }

    &.-gray {
      .card__thumbnail {
        &::before {
          background-color: map-get($colors, fiord);
        }
      }
    }

    &.-turquiose {
      background-color: map-get($colors, bondi-blue);
    }

    @include breakpoint(mobile) {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 50px;
      }
    }
  }

  &.-contact {
    flex-grow: 1;
    height: auto;
    text-align: center;

    .email,
    .phone {
      color: map-get($colors, bondi-blue);
      transition: color 0.15s ease;

      &:hover {
        color: map-get($colors, blue-lagoon);
      }

      &:active {
        color: map-get($colors, blue-stone);
      }
    }

    .phone {
      text-decoration: none;
    }

    .card__article {
      box-shadow: none;
    }

    .card__thumbnail {
      height: 182px;
      background-image: url(../images/no-photo.png);
      background-position: center center;

      &::after {
        bottom: -12%;
        height: 20%;
        border: none;
        border-top: 3px solid map-get($colors, bondi-blue);
      }
    }

    .card__body {
      z-index: 1;
      position: relative;
      height: auto;
    }

    .card__avatar,
    .card__paragraph {
      display: none;
    }

    .card__title,
    .card__paragraph {
      float: none;
    }

    .card__title {
      margin-bottom: 2px;
      text-transform: none;
      font-size: 1.6rem;
      line-height: 1.6rem;
    }

    .card__description {
      text-align: center;
    }

    .role,
    .phone {
      display: block;
    }

    .role {
      color: map-get($colors, fiord);
    }

    .role {
      margin-bottom: 2px;
      font-style: italic;
      font-size: 1.1rem;
      line-height: 1.6rem;
    }

    .phone,
    .email {
      font-size: 1.3rem;
      line-height: 2rem;
    }

    .email {
      text-decoration: none;
    }

    @include breakpoint(mobile up) {
      margin-bottom: 33px;
    }
  }

  &.-contacts.-contact {
    flex-grow: 0;

    @include breakpoint(mobile) {
      margin-bottom: 40px;
    }

    @media (min-width: 560px) and (max-width: 960px) {
      width: 50%;
    }
  }

  &.-training-sub {
    flex-grow: 0;

    @include breakpoint(mobile) {
      margin-bottom: 10px;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &Container {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    width: 100%;
    box-sizing: content-box;
    padding-right: 10px;
    padding-bottom: 10px;
    overflow: hidden;

    &.-mobile {
      display: none;

      a {
        z-index: 1;
        position: relative;
        padding-top: 50%;
        width: 50%;

        &,
        figure {
          display: block;
        }

        figure {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          box-shadow: inset 0 0 0 2px map-get($colors, white);
          backface-visibility: hidden;
        }

        &.-loading {
          background-color: map-get($colors, gallery);
        }

        &.-tablet {
          display: none;

          @include breakpoint(560px up) {
            display: block;
          }
        }

        @include breakpoint(560px up) {
          padding-top: 33.333%;
          width: 33.333%;
        }
      }

      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 4px 0 0;
        display: inline-block;
        transform: translate(-50%, -50%) scale(0.8);

        & span {
          display: inline-block;
          margin: 0 3px;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background-color: map-get($colors, white);

          @include breakpoint(tablet) {
            margin: 0 4px;
            width: 8px;
            height: 8px;
          }

          &:nth-child(1) {
            animation: loader 1.4s infinite ease;
          }

          &:nth-child(2) {
            animation: loader 1.4s infinite ease 160ms;
          }

          &:nth-child(3) {
            animation: loader 1.4s infinite ease 320ms;
          }
        }
      }

      @include breakpoint(mobile) {
        flex-flow: row wrap;
        display: flex;
        padding: 72px 4px 30px;
        box-sizing: border-box;
      }
    }

    @include breakpoint(mobile) {
      display: none;
    }
  }

  &.-isolated {
    .card__thumbnail {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-blend-mode: multiply;
    }
  }

  &Page {
    position: relative;
    left: 0;
    display: flex;
    width: 100%;
    flex-shrink: 0;
    transition: left 1s ease;
  }
}
