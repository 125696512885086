.sectionTitle {
  color: map-get($colors, fiord);

  &.-training-sub,
  &.-training-book {
    margin-bottom: 14px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;

    @include breakpoint(mobile) {
      text-align: center;
    }
  }

  &.-contact {
    h2 {
      margin-bottom: 21px;
      font-size: 3rem;
      font-weight: 600;
      line-height: 2.5rem;
      color: map-get($colors, fiord);

      @include breakpoint(mobile) {
        margin-bottom: 15px;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
      }
    }
  }
}
