.breadcrumbs {
  margin-bottom: 12px;
  padding: 0 20px;
  font-size: 1rem;
  font-style: italic;
  line-height: 40px;
  color: map-get($colors, fiord);

  & > a {
    text-decoration: none;
    color: map-get($colors, fiord);

    &::after {
      padding: 0 3px;
      content: '>';
    }
  }

  &.-back {
    align-items: center;
    margin: 0;
    padding: 0 14px;
    height: 100%;
    max-width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .breadcrumbs__link {
      display: inline;
      font-size: 1rem;
      line-height: 40px;
      color: map-get($colors, fiord);
    }

    @include breakpoint(mobile up) {
      display: none;
    }
  }
}
