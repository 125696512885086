.intraPost {
  margin-bottom: 38px;
  display: flex;
  flex-flow: column wrap;
  width: 100%;

  @include breakpoint(mobile) {
    margin-bottom: 30px;
  }

  &:last-of-type {
    margin-bottom: 50px;

    @include breakpoint(mobile) {
      margin-bottom: 20px;
    }
  }

  &:not(:last-of-type) .intraPost__separator {
    display: block;
  }

  &__figure,
  &__title,
  &__details,
  &__body,
  &__content {
    display: block;
    width: 100%;
  }

  &__figure {
    margin-bottom: 30px;
    height: 360px;
    background-size: cover;
    background-position: center center;

    @include breakpoint(tablet) {
      height: 280px;
    }

    @include breakpoint(560px) {
      margin-bottom: 12px;
      height: 137px;
    }
  }

  &__body {
    display: flex;
    flex-flow: column wrap;
  }

  &__details {
    margin-bottom: 5px;
    display: flex;
    flex-flow: row wrap;
  }

  &__date,
  &__author,
  &__city {
    font-size: 1.4rem;
    line-height: 2rem;
    font-style: italic;
    color: map-get($colors, fiord);

    @include breakpoint(mobile) {
      font-size: 1.2rem;
    }
  }

  &__date::after,
  &__author::after {
    z-index: 1;
    position: relative;
    top: -1px;
    margin: 0 3px 0 4px;
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    content: '';
    line-height: 2rem;
    vertical-align: middle;
    background-color: map-get($colors, gray);

    @include breakpoint(mobile) {
      width: 2px;
      height: 2px;
    }
  }

  &__title {
    margin-bottom: 5px;
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 700;
    color: map-get($colors, fiord);

    @include breakpoint(mobile) {
      margin-bottom: 10px;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  &__content {
    margin-bottom: 11px;
    hyphens: auto;

    @include breakpoint(mobile) {
      margin-bottom: 15px;
    }

    ol {
      list-style-position: inside;
      list-style-type: decimal;
    }

    ul {
      list-style-position: inside;
    }

    li,
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: map-get($colors, mine-shaft);

      @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    li {
      font-weight: 600;
    }
  }

  &__readMore {
    @include reset-button;
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 1.5rem;
    text-align: left;
    color: map-get($colors, bondi-blue);
    vertical-align: middle;

    @include breakpoint(mobile) {
      margin-top: -4px;
      margin-bottom: 19px;
      text-align: center;
    }

    & span {
      position: relative;
      display: inline-block;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -20px;
        width: 0;
        height: 0;
        border-right: 14px solid transparent;
        border-bottom: 14px solid map-get($colors, bondib-blue);
        border-left: 14px transparent;
        transform: rotate(-45deg) scale(0.6);
      }

      &::after {
        left: -19px;
        border-right: 12px solid transparent;
        border-bottom: 12px solid map-get($colors, white);
        border-left: 12px transparent;
      }
    }
  }

  &__footer {
    margin-bottom: 13px;
    display: flex;
    justify-content: flex-end;

    @include breakpoint(mobile) {
      margin-bottom: 20px;
      justify-content: flex-start;
    }
  }

  &__tag {
    font-size: 1.2rem;
    font-style: italic;
    text-decoration: underline;
    line-height: 2rem;
    color: map-get($colors, silver);

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__separator {
    display: block;
    width: 100%;
    height: 1px;
    background-color: map-get($colors, athens-gray);

    @include breakpoint(mobile) {
      display: none;
    }
  }
}

// long post with "read more"
.intraPost.-long {
  & .intraPost__content {
    position: relative;
    max-height: 200px;
    overflow: hidden;

    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 80px;
      background-image: linear-gradient(rgba(map-get($colors, white), 0), map-get($colors, white));
    }
  }
}
