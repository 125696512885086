//
// Global typography rules
// ==========================
html {
  font-family: $font-family;
  font-size: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;

  & a {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
  }
}

b,
strong {
  font-weight: bold;
  line-height: inherit;
}

i,
em {
  font-style: italic;
  line-height: inherit;
}

//
// Specific styles
// ==========================

.title {
  &.-h1 {
    margin-bottom: 0.4em;
    font-size: 4.2rem;
  }

  &.-h2 {
    margin-bottom: 0.4em;
    font-size: 2.8rem;
  }
}

.paragraph {
  margin-bottom: 1em;
  font-size: 1.6rem;
  line-height: 1.5em;

  &.-excerpt {
    font-size: 2rem;
  }
}
