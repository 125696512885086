.mobileNav.-rent {
  z-index: -1;
  position: absolute;
  right: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;

  .mobileNav__list {
    z-index: 15;
    position: relative;
    display: block;
    visibility: visible;
    opacity: 1;
    background-color: map-get($colors, blue-lagoon);
    transform: translateY(-100%);
    transition: 0.3s ease;

    &.-visible {
      transform: none;
    }

    &.-hidden {
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }
  }

  .mobileNav__item {
    box-shadow: inset 0 1px 0 0 map-get($colors, fiord);

    &:last-child {
      box-shadow:
        inset 0 -1px 0 0 map-get($colors, fiord),
        inset 0 1px 0 0 map-get($colors, fiord);
    }
  }

  .mobileNav__link {
    align-items: center;
    display: flex;
    padding: 0 22px;
    width: 100%;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-decoration: none;
    text-transform: uppercase;
    color: map-get($colors, white);
  }

  &.-active {
    z-index: 20;
    visibility: visible;
  }

  @include breakpoint(mobile up) {
    display: none;
  }
}

.mobileNav.-main {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 22px;
  visibility: hidden;
  max-width: 100vw;
  overflow: hidden;

  .mobileNav__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 22px;
    height: 100%;
    visibility: visible;
    opacity: 1;
    background-color: map-get($colors, bondi-blue);
    transform: translateX(100%);
    transition: 0.3s ease;
    -webkit-tap-highlight-color: rgba(map-get($colors, black), 0);

    &.-visible {
      transform: none;
    }

    &.-hidden {
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }

    &.-visible.-dropslide {
      overflow: hidden;
    }

    &.-dropslide .mobileNav__select {
      display: none;
    }
  }

  .mobileNav__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    & > .mobileNav__item {
      z-index: 5;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 60px;
      background-color: map-get($colors, bondi-blue);
      box-shadow: inset 0 -1px 0 0 rgba(map-get($colors, white), 0.5);

      &.-close {
        box-shadow: inset 0 -1px 0 0 rgba(map-get($colors, white), 0.5) !important;
      }
    }
  }

  .mobileNav__item {
    box-shadow: inset 0 -1px 0 0 rgba(map-get($colors, white), 0.5);

    &.-children::after {
      display: table;
      content: '';
      clear: both;
    }

    &.-child {
      box-shadow: inset 0 -1px 0 0 rgba(map-get($colors, fiord), 0.5);

      &:first-child {
        box-shadow:
          inset 0 -1px 0 0 rgba(map-get($colors, fiord), 0.5),
          inset 0 1px 0 0 rgba(map-get($colors, fiord), 0.5);
      }
    }

    &.-first {
      box-shadow: inset 0 -1px 0 0 rgba(map-get($colors, white), 0.5);
    }
  }

  .mobileNav__link,
  .mobileNav__dropdownInvoker,
  .mobileNav__dropslideInvoker {
    display: flex;
  }

  .mobileNav__link,
  .mobileNav__dropslideInvoker {
    -webkit-tap-highlight-color: initial;
  }

  .mobileNav__link {
    align-items: center;
    padding: 0 0 0 22px;
    width: 100%;
    height: 60px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-decoration: none;
    color: map-get($colors, white);

    .icon {
      position: relative;
      top: 1px;
      margin-left: 10px;

      &.-external-link {
        width: 14px;
        height: 13px;
      }
    }

    &.-has-children {
      float: left;
      width: calc(100% - 86px);
    }

    &.-child {
      padding: 0 0 0 44px;
    }
  }

  .mobileNav__dropdownInvoker,
  .mobileNav__dropslideInvoker {
    align-items: center;
    justify-content: center;
    float: right;
    width: 86px;
    height: 60px;
    box-shadow: inset 1px 0 0 0 rgba(map-get($colors, white), 0.5);

    .icon {
      fill: map-get($colors, white);
    }
  }

  .mobileNav__dropslideInvoker {
    box-shadow: inset 1px 0 0 0 rgba(map-get($colors, fiord), 0.5);

    .icon {
      width: 14px;
      height: 14px;
      stroke: map-get($colors, white);
    }

    &.-dropslide {
      box-shadow: inset 1px 0 0 0 rgba(map-get($colors, white), 0.5);
    }
  }

  .mobileNav__dropslide {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;

    &.-hidden {
      display: none;
    }

    &.-intra {
      padding-top: 60px;
    }
  }

  .mobileNav__dropdown {
    position: absolute;
    flex-flow: column wrap;
    display: flex;
    width: 100%;
    overflow: hidden;
    clear: both;
    background-color: map-get($colors, blue-lagoon);
  }

  .mobileNav__dropdownWrapper {
    width: 100%;

    &.-inner {
      z-index: 1;
      position: relative;
      height: 0;
      overflow: hidden;
    }
  }

  .mobileNav__close {
    padding: 0 22px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    height: 60px;

    a:not(.mobileNav__back) {
      align-items: center;
      justify-content: flex-end;
      // margin-right: 16px;
      display: flex;
      width: 20px;
      height: 100%;
    }

    .icon {
      width: 16px;
      height: 16px;
      fill: map-get($colors, white);
    }
  }

  .mobileNav__back {
    z-index: 1;
    position: relative;
    display: block;
    visibility: hidden;
    opacity: 0;

    .icon {
      width: 14px;
      height: 14px;
      stroke: map-get($colors, white);
      transform: rotate(180deg);
    }

    // increase clickbox
    &::after {
      position: absolute;
      top: -12px;
      left: -14px;
      width: 46px;
      height: 38px;
      content: '';
    }

    &.-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .mobileNav__link.-star {
    span:first-child {
      display: none;
    }

    .icon {
      margin: 0 7px 0 0;
    }
  }

  .mobileNav__stars {
    &::after {
      margin-left: 3px;
      content: attr(data-title);
    }
  }

  .mobileNav__select {
    display: none; // TEMP
    padding: 26px 22px 0;
  }

  &.-active {
    z-index: 20;
    visibility: visible;
  }

  @include breakpoint(mobile up) {
    display: none;
  }

  @include breakpoint(560px up) {
    padding-left: 40vw;

    .mobileNav__inner {
      left: 40vw;
    }
  }
}

.mobileNavOverlay {
  z-index: 15;
  position: fixed;
  top: 0;
  right: 0;
  bottom: -100px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(map-get($colors, mine-shaft), 0.7);
  -webkit-tap-highlight-color: rgba(map-get($colors, black), 0);
  transition: 0.2s ease;

  &.-visible {
    visibility: visible;
    opacity: 1;
  }

  &.-rent {
    z-index: 10;
  }

  @include breakpoint(mobile up) {
    display: none;
  }
}
