.grid__column {
  &.-contact.-left {
    .selects {
      flex-flow: row;
      justify-content: space-between;
      display: flex;

      @include breakpoint(mobile) {
        display: block;

        & > div:first-child {
          margin-bottom: 20px;
        }

        .select__title {
          margin-bottom: 5px;
        }
      }
    }

    @media (min-width: 960px) and (max-width: 1070px) {
      .selects {
        flex-flow: column wrap;
        justify-content: flex-start;
      }

      .selects > div:first-child {
        margin-bottom: 10px;
      }
    }
  }

  &.-booklets.-loop {
    h2 {
      margin-bottom: 19px;
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      color: map-get($colors, bondi-blue);
      text-transform: uppercase;
    }

    &:not(:last-child) {
      padding-bottom: 82px;
    }

    @include breakpoint(mobile) {
      padding: 0;

      h2 {
        margin-bottom: 10px;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      &:not(:last-child) {
        padding-bottom: 40px;
      }
    }
  }

  &.-booklets.-loop.-intra {
    & h2 {
      @include breakpoint(desktop up) {
        margin-bottom: 12px;
      }
    }

    &:not(:last-child) {
      padding-bottom: 40px;
    }
  }
}
