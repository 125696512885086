.intraButton {
  @include reset-button;
  margin: 1px;
  display: inline-block;
  padding: 11px 53px;
  border: 1px solid map-get($colors, bondi-blue);
  border-radius: 3px;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  color: map-get($colors, bondi-blue);
  will-change: background-color, color;
  transition: background-color 0.15s ease-out;

  &[disabled] {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    @include breakpoint(desktop up) {
      color: map-get($colors, white);
      background-color: map-get($colors, bondi-blue);
    }
  }

  &:not([disabled]):active {
    color: map-get($colors, white);
    background-color: darken(map-get($colors, bondi-blue), 10%);
  }
}
