.generic {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: map-get($colors, fiord);
  }

  h1 {
    font-size: 4rem;
    line-height: 5rem;

    @include breakpoint(mobile) {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }

  h2 {
    font-size: 4rem * 0.8;
    line-height: 5rem * 0.8;

    @include breakpoint(mobile) {
      font-size: 2rem * 0.8;
      line-height: 2.6rem * 0.8;
    }
  }

  h3 {
    font-size: 4rem * 0.7;
    line-height: 5rem * 0.7;

    @include breakpoint(mobile) {
      font-size: 2rem * 0.7;
      line-height: 2.6rem * 0.7;
    }
  }

  h3 {
    font-size: 4rem * 0.6;
    line-height: 5rem * 0.6;

    @include breakpoint(mobile) {
      font-size: 2rem * 0.6;
      line-height: 2.6rem * 0.6;
    }
  }

  .headInfo {
    width: 100vw !important;
  }
}
