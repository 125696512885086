.social {
  padding-left: 52px;

  &__title {
    display: block;
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: map-get($colors, aluminium);
  }

  &__link {
    text-decoration: none;

    .icon {
      width: 40px;
      height: 40px;
      fill: map-get($colors, bondi-blue);
      pointer-events: all;
    }

    &:first-child {
      margin-right: 20px;
    }
  }

  &.-mobile {
    display: none;
    margin: 0 auto 40px;
    padding: 0 0 40px;
    width: 100%;
    border-bottom: 1px solid map-get($colors, very-light-gray);

    @include breakpoint(mobile) {
      display: block;
    }
  }

  @include breakpoint(mobile) {
    display: none;
  }
}
