.grid {
  display: flex;
  flex-flow: row wrap;
  width: $grid-width;
  max-width: $grid-max-width;
  margin-right: auto;
  margin-left: auto;

  &.-fluid {
    max-width: none;
  }

  &.-centerItems {
    align-items: center;
    justify-content: center;
  }

  &.-verticalCenterItems {
    align-items: center;
  }

  &.-horizontalCenterItems {
    justify-content: center;
  }

  &.-pad {
    padding-right: map-get($grid-gutter, desktop);
    padding-left: map-get($grid-gutter, desktop);

    @include breakpoint(tablet) {
      padding-right: map-get($grid-gutter, mobile);
      padding-left: map-get($grid-gutter, mobile);
    }

    @include breakpoint(mobile) {
      padding-right: map-get($grid-gutter, mobile);
      padding-left: map-get($grid-gutter, mobile);
    }

    & .grid__column {
      padding-right: map-get($grid-column-gutter, desktop);
      padding-left: map-get($grid-column-gutter, desktop);

      @include breakpoint(tablet) {
        padding-right: map-get($grid-column-gutter, tablet);
        padding-left: map-get($grid-column-gutter, tablet);
      }

      @include breakpoint(mobile) {
        padding-right: map-get($grid-column-gutter, mobile);
        padding-left: map-get($grid-column-gutter, mobile);
      }
    }
  }

  &.-pad.-intra {
    max-width: 1130px;

    @include breakpoint(tablet) {
      padding-right: 44px;
      padding-left: 44px;
    }

    @include breakpoint(560px) {
      padding-right: 22px;
      padding-left: 22px;
    }
  }

  &__reset {
    margin-right: -(map-get($grid-gutter, desktop) + map-get($grid-column-gutter, desktop));
    margin-left: -(map-get($grid-gutter, desktop) + map-get($grid-column-gutter, desktop));

    @include breakpoint(tablet) {
      margin-right: -(map-get($grid-gutter, tablet) + map-get($grid-column-gutter, tablet));
      margin-left: -(map-get($grid-gutter, tablet) + map-get($grid-column-gutter, tablet));
    }

    @include breakpoint(mobile) {
      margin-right: -(map-get($grid-gutter, mobile) + map-get($grid-column-gutter, mobile));
      margin-left: -(map-get($grid-gutter, mobile) + map-get($grid-column-gutter, mobile));
    }
  }

  &__column {
    &.-noPadding {
      padding-right: 0;
      padding-left: 0;
    }

    &.-alignTop {
      align-self: flex-start;
    }

    &.-alignCenter {
      align-self: center;
    }

    &.-alignBottom {
      align-self: flex-end;
    }

    &.-desktop {
      @for $i from 1 through $grid-columns {
        &-#{$i} {
          width: #{$i * $grid-columns-width * 1%};
        }
      }

      // Set default width tablet
      @include breakpoint(tablet) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: 100%;
          }
        }
      }
    }

    &.-tablet {
      @include breakpoint(tablet) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: #{$i * $grid-columns-width * 1%};
          }
        }
      }

      // Set default width mobile
      @include breakpoint(mobile) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: 100%;
          }
        }
      }
    }

    &.-mobile {
      @include breakpoint(mobile) {
        @for $i from 1 through $grid-columns {
          &-#{$i} {
            width: #{$i * $grid-columns-width * 1%};
          }
        }
      }
    }
  }
}
