.head {
  position: relative;
  height: 120px;
  box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.3);
  background-color: map-get($colors, bondi-blue);

  & > .grid.-pad > .grid__column:first-of-type {
    max-width: 220px;

    & + .grid__column {
      flex-grow: 1;
    }
  }

  .grid {
    padding: 0 45px;
    height: 100%;

    @include breakpoint(mobile) {
      padding: 0 3px;
      height: auto;
    }
  }

  &__link {
    display: block;
    float: left;

    @include breakpoint(mobile) {
      float: none;
    }
  }

  &__figure {
    .icon {
      width: 201px;
      height: 63px;

      @include breakpoint(mobile) {
        width: 112px;
        height: 35px;
      }
    }
  }

  &__column {
    display: flex;
    height: 100%;

    &.-left {
      align-items: center;

      @include breakpoint(mobile up) {
        padding-right: 0 !important;
      }
    }

    &.-right {
      flex-flow: column wrap;
      align-items: flex-end;
      justify-content: flex-start;
      padding-top: 31px;

      @include breakpoint(mobile up) {
        padding-left: 0 !important;
      }
    }

    @include breakpoint(mobile) {
      align-items: flex-start;
      height: auto;

      &.-right,
      &.-left {
        padding-top: 23px;
      }

      &.-right {
        & > .navigation {
          display: none;
        }
      }
    }
  }

  &__mobile {
    padding-top: 12px;
    display: none;

    ul {
      display: flex;
      float: left;
    }

    li {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    a {
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-decoration: none;
      color: map-get($colors, white);
      text-transform: uppercase;

      &::after {
        margin-top: -1px;
        display: block;
        height: 1px;
        overflow: hidden;
        visibility: hidden;
        font-weight: 800;
        content: attr(data-title);
      }

      &.-active {
        font-weight: 800;
      }
    }

    @include breakpoint(mobile) {
      display: block;
    }
  }

  &__menu {
    float: right;

    .icon {
      width: 19px;
      height: 14px;
    }
  }

  @include breakpoint(mobile) {
    z-index: 15;
    height: 100px;
  }
}
