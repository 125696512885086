.flip {
  padding-top: 20px;

  &__button {
    position: relative;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
    user-select: none;

    .icon {
      width: 10px;
      height: 17px;
      fill: map-get($colors, concrete);
    }

    &:first-child {
      margin-right: 10px;
    }

    &.-enabled {
      pointer-events: all;
      cursor: pointer;

      &::after {
        position: absolute;
        top: -11px;
        left: -5px;
        width: 20px;
        height: 25px;
        content: '';
      }

      .icon {
        fill: map-get($colors, bondi-blue);
      }
    }
  }

  @include breakpoint(mobile) {
    display: none;
  }
}
