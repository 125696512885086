// Fade in
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes loader {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes checkmark {
  0% {
    top: 20px;
    visibility: hidden;
    opacity: 0;
  }

  100% {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
}
