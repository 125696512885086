.gradient {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image:
    url(../images/hero.jpg),
    linear-gradient(0deg, map-get($colors, bondi-blue) 0, white 175px);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;

  &__inner {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 0 32px;
    text-align: center;
  }

  p,
  strong {
    display: block;
  }

  p {
    margin: 0 auto;
    max-width: 250px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: map-get($colors, white);
  }

  a {
    z-index: 1;
    position: relative;
    color: map-get($colors, white);
    text-decoration: none;
  }

  a::after {
    position: absolute;
    right: 0;
    bottom: -3px;
    left: 0;
    height: 1px;
    content: '';
    background-color: map-get($colors, white);
  }
}
