.gallery {
  width: 565px;

  &__main {
    height: 450px;
    box-shadow: 0 0 0 1px map-get($colors, athens-gray);
  }

  &__isolated {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;

    figure {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      mix-blend-mode: multiply;
    }

    div {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.25;
      background-image: url(../images/gradient-gallery.jpg);
      background-size: cover;

      @supports (display: block) {
        z-index: -1;
        opacity: 1;
      }
    }
  }

  .flickity-viewport {
    height: 100% !important;
  }

  .carousel-cell {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: rgba(map-get($colors, mine-shaft), 0.6);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
      will-change: opacity;
    }

    &.-isolated {
      z-index: 1;
      position: relative;
    }
  }

  .flickity-page-dots {
    bottom: -22px;
  }

  .flickity-page-dots .dot {
    z-index: 1;
    position: relative;
    margin: 0 10px;
    width: 6px;
    height: 6px;
    border-radius: 0;
    background-color: map-get($colors, bondi-blue);
    opacity: 1;

    &.is-selected {
      box-shadow: 0 0 0 3px map-get($colors, athens-gray);
    }

    &::after {
      position: absolute;
      top: -5px;
      left: -5px;
      width: 16px;
      height: 16px;
      content: '';
    }
  }

  .flickity-prev-next-button {
    z-index: 10;
    top: auto;
    bottom: 19px;
    width: 28px;
    height: 25px;
    border-radius: 0;
    background-color: transparent;
    transform: rotate(-180deg);

    svg {
      width: 28px;
      height: 25px;
    }

    .arrow {
      fill: map-get($colors, athens-gray);
    }

    &.previous {
      left: 25px;

      svg {
        right: auto;
        left: 0;
      }
    }

    &.next {
      right: 25px;

      svg {
        right: 0;
        left: auto;
      }
    }

    &[disabled] {
      visibility: hidden;
    }
  }

  @include breakpoint(mobile) {
    margin-bottom: 28px;
    width: 100%;

    .gallery__main {
      height: 232px;
    }

    .flickity-page-dots {
      bottom: -27px;
    }

    .flickity-prev-next-button {
      display: none;
    }
  }

  @include breakpoint(tablet up) {
    .flickity-viewport.-hover {
      & ~ .flickity-prev-next-button,
      .carousel-cell::after {
        opacity: 1;
      }
    }

    .flickity-prev-next-button {
      opacity: 0;
      transition: opacity 0.3s ease;
      will-change: opacity;
    }
  }
}
