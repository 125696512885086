.content {
  &__article {
    color: map-get($colors, mine-shaft);

    &,
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: map-get($colors, mine-shaft);
    }

    p {
      display: block;

      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }

    a {
      font-weight: 700;
      color: map-get($colors, bondi-blue);
      transition: color 0.15s ease;

      &:hover {
        color: map-get($colors, blue-lagoon);
      }

      &:active {
        color: map-get($colors, blue-stone);
      }
    }
  }

  &.-training-sub {
    margin-bottom: 33px;

    .downloads > p {
      display: none;
    }
  }

  &.-training-sub {
    @include breakpoint(mobile) {
      margin: 0 -20px;
      padding-bottom: 40px;
      width: auto;
    }
  }
}
