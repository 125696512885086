.hero {
  align-items: center;
  display: flex;
  width: 100%;
  height: calc(100vh - 120px);
  max-height: 780px;
  background-image: url(../images/hero.jpg);
  background-size: cover;
  background-position: center center;

  &__inner {
    justify-content: center;
  }

  @include breakpoint(961px up) {
    min-height: 500px;
  }

  @include breakpoint(tablet) {
    z-index: 1;
    position: relative;
  }

  @include breakpoint(960px) {
    align-items: flex-start;
    padding-top: 37px;
    padding-bottom: 37px;
    height: auto;

    &.-has-inactive {
      padding-bottom: 37px + 175px;
    }
  }

  @media (min-width: 960px) and (max-width: 1048px) {
    .hero__inner {
      padding-right: 0;
      padding-left: 0;
    }

    .box.-home {
      margin: 5px;
    }
  }

  @media (min-width: 1048px) and (max-width: 1100px) {
    .hero__inner {
      padding-right: 0;
      padding-left: 0;
    }

    .box.-home {
      margin: 10px;
    }
  }
}
