.productInfo {
  &__back {
    text-decoration: none;

    span {
      position: relative;
      top: -1px;
      margin-left: 4px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: map-get($colors, fiord);
    }

    .icon {
      width: 12px;
      height: 12px;
      stroke: map-get($colors, fiord);
      transform: rotate(180deg);
    }
  }

  &__title {
    h1 {
      margin: 0 0 12px;
      font-size: 4rem;
      font-weight: 700;
      line-height: 5rem;
      text-transform: uppercase;
      color: map-get($colors, fiord);
    }
  }

  &__container {
    display: flex;
  }

  &__aside {
    flex-grow: 1;
    padding-left: 40px;
  }

  &__description {
    padding-bottom: 61px;

    p {
      display: block;
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: map-get($colors, fiord);

      @include breakpoint(mobile up) {
        white-space: nowrap;
      }
    }
  }

  &__buttons {
    padding-top: 34px;
    display: flex;

    .button {
      &:first-child {
        margin-right: 25px;
      }

      &:last-child {
        justify-content: center;
        display: flex;

        span {
          display: block;
        }
      }

      @include breakpoint(mobile) {
        &:last-child {
          padding: 0;
          width: 186px;
        }
      }
    }
  }

  @include breakpoint(mobile) {
    margin: 0 -20px;
    padding-top: 19px;
    padding-bottom: 49px;

    .productInfo__back {
      display: none;
    }

    .productInfo__title h1 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    .productInfo__container {
      flex-flow: column wrap;
    }

    .productInfo__aside {
      padding: 0;
    }

    .productInfo__description {
      padding-top: 22px;
      padding-bottom: 37px;
    }

    .productInfo__downloads {
      padding-bottom: 50px;

      .download__link {
        padding: 0 14px;
      }

      .download:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .productInfo__buttons {
      padding: 0;

      .button.-wide {
        margin: 0 auto;
      }

      .button.-wide .button__caption {
        font-size: 1.6rem;
      }
    }
  }
}
