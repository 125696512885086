.campaign {
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 350px;
  text-decoration: none;
  color: map-get($colors, white);
  background-size: cover;
  background-position: center center;

  &__column {
    &.-left {
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      text-align: center;

      div {
        padding-right: 30px;

        @include breakpoint(mobile) {
          padding: 0;
        }
      }

      @include breakpoint(mobile) {
        justify-content: center;
      }
    }
  }

  &__title,
  &__paragraph {
    width: 100%;
    color: map-get($colors, white);
  }

  &__title {
    margin-bottom: 6px;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5rem;
    text-transform: uppercase;

    @include breakpoint(mobile) {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }

  &__paragraph {
    margin: 0 auto;
    max-width: 560px;
    font-size: 2rem;
    line-height: 2.5rem;
    color: map-get($colors, white);

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    @include breakpoint(mobile) {
      font-size: 1.4rem;
      line-height: 1.8rem;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  &__image {
    @include breakpoint(mobile) {
      display: block;
      max-width: 115px;
      margin: 0 auto;
    }
  }

  @include breakpoint(mobile) {
    &.-center {
      padding-top: 30px;
      padding-bottom: 25px;
      min-height: 0;
    }
  }

  &.-left {
    z-index: 1;
    position: relative;
    display: block;

    .campaign__grid,
    .campaign__color,
    .campaign__image,
    .campaign__triangle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .campaign__grid {
      z-index: 3;
    }

    .campaign__color {
      right: 39%;
      z-index: 2;
      background-color: map-get($colors, yellow-sea);
    }

    .campaign__content {
      position: relative;
      z-index: 2;
      align-items: center;
      display: flex;
      height: 100%;
      max-width: 1070px;
    }

    .campaign__paragraph {
      margin: 0 0 36px;
    }

    .campaign__image {
      left: auto;
      width: 39%;
      background-position: center;
      background-size: cover;
    }

    .campaign__triangle {
      left: 61%;
      width: 500px;
      background-color: map-get($colors, yellow-sea);
      transform: skewX(-43deg) translateX(-334px);
    }

    .campaign__button {
      @include reset-button;
      display: block;
      width: 250px;
      height: 50px;
      border-radius: 3px;
      font-family: 'Source Sans Pro', Helvetica, Arial, serif;
      background-color: map-get($colors, white);
      box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.3);
    }

    .campaign__button span {
      font-size: 2rem;
      line-height: 2.5rem;
      color: map-get($colors, harvest-gold);
    }

    @media (min-width: 980px) and (max-width: 1110px) {
      .campaign__grid {
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    @include breakpoint(tablet) {
      min-height: 0;

      .campaign__image,
      .campaign__triangle,
      .campaign__color {
        display: none;
      }

      .campaign__grid {
        position: static;
        padding: 54px 35px 47px;
        background-color: map-get($colors, yellow-sea);
      }

      .campaign__content {
        justify-content: center;
      }

      .campaign__title {
        margin: 0 auto 10px;
        max-width: 200px;
        text-align: center;
      }

      .campaign__paragraph {
        margin: 0 auto 45px;
        max-width: 400px;
        text-align: center;
      }

      .campaign__paragraph p {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      .campaign__paragraph p:not(:last-child) {
        margin-bottom: 1.8rem;
      }

      .campaign__button {
        margin: 0 auto;
        background-color: transparent;
        box-shadow: inset 0 0 0 1px map-get($colors, white);
      }

      .campaign__button span {
        color: map-get($colors, white);
      }
    }

    @include breakpoint(mobile) {
      .campaign__paragraph {
        text-align: left;
      }
    }
  }
}
