.perk {
  display: flex;

  &__inner {
    z-index: 1;
    position: relative;
    flex-grow: 1;
    margin-bottom: 55px;
    min-height: 450px;
    padding: 37px 26px 30px;
    overflow: hidden;
    text-align: center;
    background-color: map-get($colors, yellow-sea);

    .icon {
      width: 47px;
      height: 47px;
      fill: none;
      stroke: map-get($colors, white);

      &.-discussion,
      &.-piggybank {
        width: 50px;
        height: 50px;
      }

      &.-warehouse,
      &.-warehouse-alt {
        width: 46px;
        height: 46px;
      }
    }

    &::after {
      z-index: -1;
      position: absolute;
      right: 0;
      bottom: -115px;
      left: 0;
      height: 195px;
      content: '';
      background-color: map-get($colors, gamboge);
      backface-visibility: hidden;
      transform: skewY(-6deg);
    }
  }

  &__title,
  &__description {
    display: block;
    color: map-get($colors, white);
  }

  &__title {
    z-index: 1;
    position: relative;
    padding-top: 10px;
    padding-bottom: 16px;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 3.3rem;
    text-transform: uppercase;

    &::after {
      position: absolute;
      right: 30px;
      bottom: 0;
      left: 30px;
      height: 3px;
      content: '';
      background-color: map-get($colors, white);
    }
  }

  &__description {
    padding-top: 22px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: left;
  }

  @include breakpoint(mobile) {
    padding: 0 !important;
    width: auto !important;

    .perk__inner {
      margin-bottom: 40px;
      padding-bottom: 42px;
      min-height: 0;
    }

    .perk__inner .icon {
      width: 39px;
      height: 39px;
    }

    .perk__title {
      padding-top: 15px;
      padding-bottom: 14px;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    .perk__title::after {
      display: none;
    }

    .perk__description {
      padding-top: 0;
    }

    &:last-child .perk__inner {
      margin-bottom: 50px;
    }
  }
}
