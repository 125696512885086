.intraNews {
  &__button {
    width: 100%;

    @include breakpoint(mobile) {
      text-align: center;
    }
  }

  &__loader {
    display: block;
    padding-top: 5px;
    padding-left: 2px;
    width: 100%;
    font-size: 1.4rem;
    font-style: italic;
    line-height: 2rem;
    color: map-get($colors, fiord);
    text-align: left;

    @include breakpoint(tablet) {
      text-align: center;
    }
  }
}
