.grid {
  h2 {
    margin-bottom: 10px;
    font-size: 3rem;
    font-weight: 600;
    line-height: 4rem;
    color: #505865;
  }

  &.-list-grid {
    padding-top: 120px;

    @include breakpoint(mobile) {
      padding-top: 0;
    }
  }

  &.-list-downloads {
    @include breakpoint(mobile) {
      padding: 33px 0 50px;

      .download:not(:last-child) {
        margin-bottom: 10px;
      }

      .download__time {
        display: none;
      }
    }
  }

  &.-list-button {
    @include breakpoint(mobile) {
      padding: 0 0 50px;

      .button {
        width: 186px;
        height: 50px;
        box-shadow: none;
      }

      .button__caption {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }
}

.grid__reset {
  margin-right: -40px;
  margin-left: -40px;
}
