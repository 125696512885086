.book {
  &__response {
    margin-right: 10px;
    align-items: center;
    display: none;
    float: right;
    height: 50px;
    font-size: 1.4rem;

    &.-red {
      color: map-get($colors, well-read);
    }

    &.-orange {
      color: map-get($colors, meteor);
    }

    &.-green {
      color: map-get($colors, green);
    }

    &.-visible {
      display: flex;
    }

    @include breakpoint(tablet) {
      z-index: 1;
      position: relative;
      top: -60px;
      width: 100%;
      float: none;
      height: auto;
      justify-content: center;
    }
  }
}
