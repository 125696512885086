.intraGrid {
  &__grid {
    flex-flow: row nowrap;
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(mobile) {
      padding: 0;
      flex-flow: column nowrap;
      justify-content: flex-start;
    }
  }

  &__column {
    padding: 0;

    & .grid__column {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    &.-desktop-12 {
      width: 100%;
    }

    &.-desktop-9 {
      width: 806px;

      @include breakpoint(1140px) {
        width: 75%;
      }

      @include breakpoint(mobile) {
        width: 100%;
      }
    }

    &.-desktop-8 {
      width: 748px;

      @include breakpoint(1140px) {
        width: 66.66667%;
      }

      @include breakpoint(mobile) {
        width: 100%;
      }
    }

    &.-desktop-3 {
      width: 202px;

      @include breakpoint(1140px) {
        padding-left: 41px;
        width: 25%;
      }

      @include breakpoint(mobile) {
        padding-left: 0;
        width: 100%;
      }
    }
  }
}
