.intro {
  margin: 0 auto;
  padding: 113px 0 99px;
  text-align: center;

  &__caption {
    font-size: 3rem;
    font-style: italic;
    line-height: 3.8rem;
    color: map-get($colors, bondi-blue);

    @include breakpoint(mobile) {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
  }

  @include breakpoint(mobile) {
    padding: 40px 0 50px;
  }
}
