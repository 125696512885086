.footer {
  z-index: 1;
  position: relative;
  display: flex;
  padding: 53px 0 72px;
  width: 100%;

  &__column {
    display: flex;

    &.-right {
      justify-content: flex-end;

      @include breakpoint(mobile) {
        justify-content: flex-start;
      }
    }

    &.-left {
      justify-content: flex-start;
    }

    @include breakpoint(mobile) {
      flex-flow: row wrap;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &__border {
    width: 100%;
    height: 1px;
    background-color: map-get($colors, iron);

    @include breakpoint(mobile) {
      display: none;
    }
  }

  @include breakpoint(mobile) {
    flex-flow: row wrap;
    padding: 0 0 72px;
    text-align: center;
  }
}
