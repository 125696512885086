.download {
  height: 50px;
  background-color: map-get($colors, wild-sand);

  &__link {
    align-items: center;
    padding: 11px;
    display: flex;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  &__inner {
    position: relative;
    align-items: center;
    display: flex;
    width: 100%;

    .-pdf {
      width: 20px;
      height: 24px;
      fill: map-get($colors, bondi-blue);
    }

    .-arrow-custom-right-wall,
    .-star-o,
    .-star {
      margin-left: auto;
      width: 14px;
      height: 13px;
      fill: map-get($colors, mine-shaft);
      transform: rotate(90deg);
    }

    .-star-o,
    .-star {
      width: 17px;
      height: 16px;
      transform: none;
    }
  }

  &__title {
    padding-left: 12px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: map-get($colors, fiord);
  }

  &__time {
    position: absolute;
    right: 26px;

    span {
      position: relative;
      top: 1px;
      font-size: 1.2rem;
      font-style: italic;
      color: map-get($colors, fiord);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid map-get($colors, white);
  }

  &.-blue {
    .-pdf {
      fill: map-get($colors, bondi-blue);
    }
  }

  &.-orange,
  &.-yellow {
    .-pdf {
      fill: map-get($colors, yellow-sea);
    }
  }

  &.-gray,
  &.-black {
    .-pdf {
      fill: map-get($colors, mine-shaft);
    }
  }

  &.-green {
    .-pdf {
      fill: map-get($colors, green);
    }
  }

  &.-star {
    background-color: map-get($colors, bleach-white);

    .download__title {
      padding: 0;
      font-style: italic;
    }

    .icon.-pdf {
      display: none;
    }
  }

  &.-generic-sidebar {
    @include breakpoint(mobile) {
      margin: 0 -22px 50px -22px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &.-booklets {
    @include breakpoint(mobile) {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &.-booklets.-intra {
    & .download__time {
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}
