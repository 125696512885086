.modal {
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &__background {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &.-city {
    .modal__background {
      background-color: rgba(map-get($colors, abbey), 0.9);
    }

    .modal__container {
      padding: 33px 59px 50px;
      max-width: 463px;
      text-align: center;
      background-color: map-get($colors, bondi-blue);
    }

    .modal__title,
    .modal__description {
      display: block;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: map-get($colors, white);
    }

    .modal__title {
      font-weight: 700;
    }

    .modal__controls {
      justify-content: space-between;
      display: flex;
      padding-top: 19px;
    }

    @include breakpoint(mobile) {
      .modal__background {
        padding: 0 22px;
      }

      .modal__container {
        padding: 30px 15px 73px;
        width: 100%;
      }

      .modal__controls {
        flex-flow: column wrap;
        padding: 30px 25px 0;
      }

      .modal__controls .select,
      .modal__controls .select__container,
      .modal__controls .select__native {
        width: 100%;
      }

      .modal__controls .select {
        margin-bottom: 36px;
      }

      .modal__controls .button {
        margin: 0 auto;
        width: 125px;
        height: 40px;
      }
    }
  }

  &.-visible {
    opacity: 1;
    pointer-events: all;
  }

  &.-hidden {
    display: none;
  }
}
