.table {
  width: 100%;

  &__column {
    vertical-align: middle;
    text-align: left;

    a {
      z-index: 1;
      position: relative;

      &::after {
        position: absolute;
        top: -11px;
        left: -6px;
        width: 27px;
        height: 27px;
        content: '';
      }
    }

    span {
      font-size: 1.6rem;
      line-height: 3.125rem;
      color: map-get($colors, mine-shaft);
    }

    .icon {
      width: 15px;
      height: 15px;
      fill: map-get($colors, fiord);
    }

    &.-head {
      span {
        font-weight: 600;
        text-transform: uppercase;
        color: map-get($colors, bondi-blue);
      }
    }

    &:first-child {
      padding-left: 21px;
      width: 46%;

      span {
        font-weight: 600;
      }
    }

    &:nth-child(2) {
      width: 23%;
    }

    &:nth-child(3) {
      min-width: 210px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }

  &__link {
    display: inline-block;
    width: 100%;
    outline: none;
    line-height: 50px;
    text-decoration: none;
  }

  &__row {
    height: 50px;

    &:not(:last-child) {
      border-bottom: 2px solid map-get($colors, white);
    }
  }

  &__body {
    background-color: map-get($colors, wild-sand);

    &.-accessories {
      .js-star-invoker {
        display: none;
      }
    }
  }

  @include breakpoint(mobile) {
    flex-flow: row wrap;
    margin: 0 -40px;
    display: flex;
    width: auto;

    .table__head {
      display: none;
    }

    .table__body,
    .table__row {
      width: 100%;
    }

    .table__body {
      padding: 0 10px;
      background-color: transparent;

      &.-accessories {
        padding-top: 40px;
      }
    }

    .table__row {
      z-index: 1;
      position: relative;
      padding: 6px 12px 12px;
      display: block;
      height: auto;
      background-color: map-get($colors, wild-sand);

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .table__body .table__column {
      padding: 0;
      display: flex;
      width: 100%;
      min-width: 0;

      &:not(:nth-last-child(2)) {
        margin-bottom: 5px;
      }

      &:last-child {
        position: absolute;
        right: 0;
        bottom: 0;
        justify-content: flex-end;
        margin: 0;

        .icon {
          position: relative;
          right: 12px;
          bottom: 13px;
          width: 17px;
          height: 16px;
          cursor: pointer;
        }
      }
    }

    .table__body .table__column::before {
      width: 30%;
      content: attr(data-attr);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
      color: map-get($colors, bondi-blue);
    }

    .table__link {
      display: block;
      width: 70%;
      line-height: normal;
    }

    .table__link span {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: map-get($colors, mine-shaft);
    }
  }
}
