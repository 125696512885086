.sub {
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: -50px;
  left: 0;
  flex-flow: row nowrap;
  display: flex;
  height: 50px;
  overflow: hidden;
  pointer-events: none;

  &__spring {
    flex-basis: auto;
    height: 100%;
  }

  &__wrap,
  &__inner {
    width: 100%;
    height: 100%;
  }

  &__inner {
    top: -50px;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    padding-right: 15px;
    display: flex;
    width: auto;
    background-color: map-get($colors, blue-lagoon);
    transition: 0.2s linear;

    &::after {
      z-index: -1;
      position: absolute;
      top: 0;
      right: -5000%;
      bottom: 0;
      left: -5000%;
      content: '';
      background-color: map-get($colors, blue-lagoon);
    }
  }

  &__wrap {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    width: auto;
  }

  &__item {
    &:not(:last-child) {
      padding-right: 22px;
    }

    a {
      box-shadow:
        0 12px 0 0 map-get($colors, blue-lagoon),
        0 20px 0 0 transparent;
      transition: box-shadow 0.1s ease;
    }

    &:hover {
      a {
        box-shadow:
          0 12px 0 0 map-get($colors, blue-lagoon),
          0 20px 0 0 map-get($colors, white);
      }
    }
  }

  &__link {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-decoration: none;
    text-transform: uppercase;
    color: map-get($colors, white);
  }
}
