.select {
  position: relative;

  &__container {
    display: block;
    width: 104px;
    height: 24px;
    border-radius: 1px;
    background-color: map-get($colors, gallery);
  }

  &__caption,
  &__button {
    display: flex;
  }

  &__caption {
    position: relative;
    top: -1px;
    align-items: center;
    padding: 0 8px;
    float: left;
    width: 104px - 30px;
    height: 100%;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: map-get($colors, armadillo);

    &.-hidden {
      opacity: 0;
    }
  }

  &__button {
    align-items: center;
    justify-content: center;
    float: right;
    width: 30px;
    height: 100%;
    border-radius: 1px;
    background-color: map-get($colors, very-light-gray);

    .icon {
      position: relative;
      top: -1px;
      width: 8px;
      height: 5px;
      fill: map-get($colors, white);
    }
  }

  &__native {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    opacity: 0;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 15px;
    display: block;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    color: map-get($colors, bondi-blue);

    &.-training {
      @include breakpoint(mobile) {
        margin: 0 -20px 5px;
        width: auto;
      }
    }
  }

  &.-header {
    .select__container {
      background-color: map-get($colors, blue-lagoon);
    }

    .select__caption {
      color: map-get($colors, white);
    }

    .select__button {
      background-color: map-get($colors, blue-lagoon-alt);
    }
  }

  &.-large {
    float: left;

    .select__container {
      width: 261px;
      height: 40px;
    }

    .select__caption {
      top: 0;
      padding: 0 13px;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .select__native {
      height: 40px;
    }

    .select__button {
      width: 40px;

      .icon {
        top: 0;
        width: 10px;
        height: 6px;
      }
    }
  }

  &.-modal {
    .select__container,
    .select__native {
      width: 226px;
      height: 40px;
    }

    .select__caption {
      padding: 10px 13px;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .select__button {
      width: 42px;

      .icon {
        top: 0;
        width: 10px;
        height: 7px;
      }
    }
  }

  &.-mobile {
    width: 100%;

    .select__container,
    .select__native {
      width: 100%;
      height: 40px;
    }

    .select__container {
      background-color: map-get($colors, blue-lagoon-alt);
    }

    .select__button {
      width: 40px;
      background-color: map-get($colors, blue-lagoon);
    }

    .select__caption {
      padding: 0 11px;
      width: calc(100% - 40px);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      color: map-get($colors, white);
    }
  }

  &.-contact {
    @include breakpoint(mobile) {
      &,
      .select__container,
      .select__native {
        float: none;
        width: 100%;
      }
    }
  }

  &.-training {
    @include breakpoint(mobile) {
      margin: 0 -20px;
      float: none;
      width: auto;

      .select__container,
      .select__native {
        width: 100%;
      }
    }
  }

  &.-intra {
    float: none;
    width: 300px;

    @include breakpoint(560px) {
      width: 100%;
    }

    & .select__container {
      width: 100%;
    }

    & .select__caption {
      width: calc(100% - 40px);
    }

    & .select__button {
      display: flex;
      flex-flow: column nowrap;
    }

    & .select__button .icon:first-child {
      margin-bottom: 2px;
      transform: rotate(180deg);
    }
  }
}
