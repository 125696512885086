.headInfo {
  max-width: 860px;

  &__image {
    margin-bottom: 50px;
    height: 448px;
    background-size: cover;
    background-position: center center;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5rem;
    color: map-get($colors, fiord);

    @include breakpoint(mobile) {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  &__description {
    padding-bottom: 76px;
    max-width: 605px;
    color: map-get($colors, mine-shaft);

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: map-get($colors, mine-shaft);

      @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    a {
      text-decoration: none;
      color: map-get($colors, bondi-blue);
      transition: color 0.15s ease;

      &:hover {
        color: map-get($colors, blue-lagoon);
      }

      &:active {
        color: map-get($colors, blue-stone);
      }
    }
  }

  &.-listing {
    .headInfo {
      &__description {
        padding-bottom: 21px;
      }
    }

    @include breakpoint(mobile) {
      margin: 0 -20px;
      width: auto;
      padding-top: 20px;

      .headInfo__title {
        margin-bottom: 10px;
        font-size: 2rem;
        line-height: 2.8rem;
      }

      .headInfo__description {
        display: none;
      }
    }
  }

  &.-training {
    padding-top: 78px;
    width: 100%;
    max-width: none;

    .headInfo__title {
      margin-bottom: 20px;
    }

    .headInfo__description {
      padding-bottom: 32px;
      max-width: 660px;
    }

    @include breakpoint(mobile) {
      margin: 0 -20px;
      padding-top: 20px;
      width: auto;

      .headInfo__title {
        margin-bottom: 8px;
      }

      .headInfo__description {
        padding-bottom: 20px;
      }
    }
  }

  &.-training-sub,
  &.-training-book {
    .headInfo__title {
      margin-bottom: 22px;
    }
  }

  &.-training-sub {
    .headInfo__description {
      display: none;
    }

    @include breakpoint(mobile) {
      margin: 0 -20px;
      padding-top: 20px;
      width: auto;

      .headInfo__title {
        margin-bottom: 10px;
      }
    }
  }

  &.-training-book {
    .headInfo__title {
      margin-bottom: 11px;
      max-width: 350px;
    }

    .headInfo__description {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.4rem;
      color: map-get($colors, fiord);

      *:not(.title) {
        color: map-get($colors, bondi-blue);
      }

      time {
        margin-right: 17px;
      }

      .title {
        color: map-get($colors, fiord);
      }
    }

    @include breakpoint(mobile) {
      margin: 0 -20px;
      width: auto;

      .headInfo__title {
        margin-bottom: 10px;
        padding-top: 20px;
        font-size: 2rem;
        line-height: 2.6rem;
      }

      .headInfo__description {
        padding-bottom: 40px;
      }

      .headInfo__description * {
        font-size: 1.4rem;
        line-height: 2rem;
      }

      .headInfo__description time {
        margin: 0;
      }

      .headInfo__description time:first-of-type::after {
        display: table;
        content: '';
        clear: both;
      }
    }
  }

  &.-contact {
    .headInfo__title {
      margin-bottom: 20px;
    }

    .headInfo__description {
      padding-bottom: 32px;
    }

    @include breakpoint(mobile) {
      .headInfo__title {
        margin-bottom: 14px;
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
  }

  &.-about {
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include breakpoint(tablet up) {
      .headInfo__description {
        padding-right: 60px;
      }
    }

    @include breakpoint(mobile) {
      margin: 0 -40px;
      width: auto !important;

      .headInfo__image {
        margin-bottom: 25px;
        height: 250px;
      }

      .headInfo__title,
      .headInfo__description {
        padding: 0 22px;
      }

      .headInfo__title {
        margin-bottom: 10px;
        font-size: 2rem;
        line-height: 2.8rem;
      }

      .headInfo__description {
        padding-bottom: 40px;
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
  }

  &.-generic-sidebar,
  &.-starred {
    &,
    .headInfo__description {
      width: 100%;
      max-width: none;
    }

    .headInfo__description {
      p:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }
  }

  &.-starred {
    margin: 0 auto;
    max-width: 570px;

    .headInfo__description {
      padding-bottom: 41px;
    }

    @include breakpoint(mobile) {
      margin: 0 -40px;
      padding-top: 21px;
    }
  }

  &.-categories {
    width: 100%;

    &,
    .headInfo__description {
      max-width: none;
    }

    @include breakpoint(mobile) {
      width: auto;
      margin: 0 -40px;

      .headInfo__image {
        margin-bottom: 26px;
        height: 250px;
      }

      .headInfo__title,
      .headInfo__description {
        padding: 0 20px;
      }

      .headInfo__title {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      .headInfo__description {
        margin-bottom: 29px;
      }

      &.-listing {
        padding-top: 20px;
      }

      &.-listing .headInfo__title {
        margin-bottom: 4px;
      }

      &.-listing .headInfo__description {
        display: none;
      }
    }
  }

  &.-rent.-mobile {
    padding-top: 20px;

    .headInfo__title {
      margin-bottom: 10px;
      font-size: 2rem;
      line-height: 2.8rem;
    }

    .headInfo__description {
      display: none;
    }
  }

  &.-booklets {
    @include breakpoint(mobile) {
      padding-top: 22px;
    }
  }

  &.-values {
    strong {
      text-transform: uppercase;
      color: map-get($colors, bondi-blue);
    }
  }

  &.-sortiment {
    @include breakpoint(tablet up) {
      padding-top: 78px;
    }

    @include breakpoint(mobile) {
      padding-top: 22px;

      .headInfo__title {
        margin-bottom: 6px;
        font-size: 2rem;
        line-height: 2.6rem;
      }

      .headInfo__description {
        padding-bottom: 40px;
      }

      .headInfo__description p {
        font-size: 1.4rem;
        line-height: 2rem;
      }

      .headInfo__description p:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &.-generic-sidebar.-no-image,
  &.-categories.-no-image {
    @include breakpoint(mobile) {
      padding-top: 20px;
    }
  }

  &.-error {
    width: 100%;
    max-width: none;

    @include breakpoint(tablet) {
      .headInfo__description {
        display: block !important;
      }

      .headInfo__title {
        display: none;
      }
    }
  }

  &.-training.-temp {
    max-width: none;

    .headInfo__description {
      max-width: 800px;

      & > p:first-of-type {
        margin-bottom: 0;
        max-width: 660px;
      }
    }

    span {
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 700;
      color: map-get($colors, bondi-blue);

      @include breakpoint(mobile) {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }

    span:first-of-type {
      margin-bottom: 4px;
      padding-top: 20px;
      display: block;
    }

    table {
      margin-bottom: 20px;
      width: 100%;
    }

    td {
      width: 50%;
      font-size: 1.6rem;
      line-height: 2.4rem;

      @include breakpoint(mobile) {
        display: block;
        width: 100%;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  &.-intra {
    font-size: 1.6rem;
    line-height: 2.4rem;

    & .headInfo__description {
      max-width: none;
      padding-right: 45px;
      padding-bottom: 33px;

      @include breakpoint(560px) {
        padding-right: 0;
        padding-bottom: 28px;
      }
    }

    @include breakpoint(tablet) {
      & .headInfo__title {
        margin-bottom: 10px;
        font-size: 2rem;
        line-height: 2.8rem;
      }

      & .headInfo__description {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
  }

  &.-products {
    .headInfo__title {
      margin-bottom: 43px;

      @include breakpoint(960px) {
        display: none;
      }
    }

    .headInfo__description {
      display: none;
    }
  }
}

// For product list view images
.headInfoImage {
  margin-bottom: 20px;
  display: block;
  float: right;
  max-height: calc(100% - 21px);
}
