.boxAssortment {
  z-index: 1;
  position: relative;
  margin-bottom: 40px;
  width: 100%;

  &::after {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    box-shadow: 0 0 0 1px map-get($colors, athens-gray);
  }

  &__head {
    z-index: 1;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 59px;
    overflow: hidden;
    background-color: map-get($colors, bondi-blue);

    &::after {
      z-index: -1;
      position: absolute;
      left: 0;
      bottom: -40px;
      width: 100%;
      height: 100%;
      content: '';
      background-color: map-get($colors, eastern-blue-alt);
      transform: skewY(-7deg);
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
      color: map-get($colors, white);
      text-transform: uppercase;
    }
  }

  &__body {
    display: flex;
    width: 100%;
    background-color: map-get($colors, white);
  }

  &__list {
    padding: 19px 0 32px;
    width: 100%;
  }

  &__item {
    text-align: center;
  }

  &__link {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.6rem;
    text-decoration: none;
    color: map-get($colors, bondi-blue);
  }

  &__button {
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40px;
    height: 40px;
    background-color: map-get($colors, athens-gray);

    .icon {
      fill: map-get($colors, white);
    }
  }

  &.-last {
    margin-bottom: 0;
  }
}
