.intraSidebar {
  display: block;
  float: right;
  width: 100%;
  padding: 17px 27px 174px 19px;
  background-color: map-get($colors, white-smoke);

  @include breakpoint(mobile) {
    display: none;
  }

  &.-documents {
    min-height: 0;
    padding-bottom: 24px;

    & .intraSidebar__list {
      margin-bottom: 0;
    }

    & .intraSidebar__listItem.-active a {
      font-weight: 700;
      color: map-get($colors, bondi-blue);
    }
  }

  &__title,
  &__list,
  &__listItem,
  &__separator {
    display: block;
    width: 100%;
  }

  &__title,
  &__listItem,
  &__listItem a {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: map-get($colors, armadillo);
  }

  &__list {
    margin-bottom: 24px;
  }

  &__listItem a {
    text-decoration: none;
  }

  &__listItem a:hover {
    text-decoration: underline;
  }

  &__separator {
    margin-bottom: 20px;
    height: 1px;
    background-color: map-get($colors, athens-gray);
  }
}
