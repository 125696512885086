.iconLabel {
  padding-top: 90px;

  .icon {
    position: relative;
    top: -2px;
    margin-right: 4px;
    line-height: 1.8rem;
    vertical-align: middle;
  }

  &__link {
    text-decoration: none;
    vertical-align: middle;
  }

  &__title {
    font-size: 1.4rem;
  }

  &.-training-sub {
    margin-bottom: 23px;

    .icon {
      width: 14px;
      height: 13px;
      stroke: map-get($colors, bondi-blue);
      transform: rotate(180deg);
      transition: stroke 0.15s ease;
    }

    .iconLabel__title {
      color: map-get($colors, bondi-blue);
      transition: color 0.15s ease;
    }

    &:hover {
      .iconLabel__title {
        color: map-get($colors, blue-lagoon);
      }

      .icon {
        stroke: map-get($colors, blue-lagoon);
      }
    }

    &:active {
      .iconLabel__title {
        color: map-get($colors, blue-stone);
      }

      .icon {
        stroke: map-get($colors, blue-stone);
      }
    }
  }
}
