.box {
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  &__inner {
    padding-top: 41px;
    text-align: center;
    background-size: cover;
    background-position: center center;
  }

  &__title,
  &__description {
    color: map-get($colors, white);
  }

  &__title {
    position: relative;
    font-size: 2.6rem;
    line-height: 3.3rem;
    font-weight: 700;
    text-transform: uppercase;

    &::after {
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -53px;
      width: 106px;
      height: 4px;
      content: '';
      background-color: map-get($colors, white);
    }
  }

  &__description {
    padding-top: 20px;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  &__isolated {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;

    figure {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      mix-blend-mode: multiply;
    }

    div {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.25;
      background-image: url(../images/gradient-card.jpg);
      background-size: cover;

      @supports (display: block) {
        z-index: -1;
        opacity: 1;
      }
    }
  }

  &.-isolated {
    .box__link {
      z-index: 1;
      position: relative;
    }
  }

  &.-home {
    flex-basis: 463px;
    justify-content: center;
    margin: 20px;
    max-width: 463px;
    height: 200px;
    background-color: rgba(map-get($colors, eastern-blue), 0.9);
    box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.5);

    &:last-child {
      &.-home {
        position: relative;
        background-color: rgba(map-get($colors, abbey), 0.9);

        &::after {
          position: absolute;
          right: 0;
          bottom: 27px;
          left: 0;
          height: 45px;
          content: '';
          background-image: url(../images/xl-bygg-logo.png);
          background-repeat: no-repeat;
          background-position-x: center;
          pointer-events: none;
        }
      }
    }

    &.-inactive {
      position: relative;
      cursor: not-allowed;

      @include breakpoint(mobile) {
        display: none;
      }
    }

    @include breakpoint(mobile) {
      margin: 0 15px;
      height: 70px;

      .box__inner {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0;
        height: 100%;
      }

      .box__title {
        font-size: 2rem;
        line-height: 2.5rem;

        &::after {
          display: none;
        }
      }

      .box__description {
        display: none;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  &.-rent {
    padding: 0 20px !important;
    margin-bottom: 46px;
    height: 262px;
    float: left;

    .box {
      &__inner {
        position: relative;
        align-items: flex-end;
        justify-content: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.5);

        &::after {
          position: absolute;
          left: 0;
          bottom: -116px;
          width: 100%;
          height: 212px;
          content: '';
          transform: skewY(-5.5deg);
          backface-visibility: hidden;
          background-color: rgba(map-get($colors, bondi-blue), 0.9);
        }
      }

      &__title {
        top: -39px;
        z-index: 1;
        font-size: 2rem;
        line-height: 2.5rem;

        &::after {
          bottom: -8px;
          margin-left: -41px;
          width: 82px;
          height: 2px;
        }
      }

      &__description {
        display: none;
      }
    }

    @include breakpoint(mobile) {
      margin-bottom: 22px;
      padding: 0 !important;

      .box__inner {
        box-shadow: none;
      }

      .box__title::after {
        display: none;
      }

      &:first-child {
        margin-top: 22px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }

    @media (min-width: 960px) and (max-width: 1100px) {
      width: 50%;
    }

    @media (min-width: 700px) and (max-width: 960px) {
      width: 50%;
      padding-right: 15px !important;
      padding-left: 15px !important;

      &:first-child {
        margin-top: 0;
      }

      &:first-child,
      &:nth-child(2) {
        margin-top: 30px;
      }
    }
  }

  &.-rent.-multiline {
    .box__inner::after {
      bottom: -85px;
    }

    .box__title {
      display: block;
    }
  }

  &.-category {
    padding: 0 20px !important;
    margin-bottom: 41px;
    height: 103px;
    float: left;

    .box {
      &__link {
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.5);
        background-color: map-get($colors, bondi-blue);

        &::after {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100px;
          content: '';
          background: darken(map-get($colors, bondi-blue), 2%);
          transform: skewY(-5deg);

          @include breakpoint(960px up) {
            top: 79px;
            transform: skewY(-5deg);
          }

          @media (min-width: 730px) and (max-width: 960px) {
            top: 65px;
            transform: skewY(-3deg);
          }

          @media (min-width: 530px) and (max-width: 730px) {
            top: 65px;
            transform: skewY(-3deg);
          }

          @media (min-width: 400px) and (max-width: 530px) {
            top: 75px;
            transform: skewY(-4deg);
          }
        }
      }

      &__inner {
        z-index: 1;
        position: relative;
        top: -1px;
        padding: 0;
      }

      &__title,
      &__description {
        font-size: 1.6rem;
        line-height: 2rem;
      }

      &__title {
        &::after {
          display: none;
        }
      }

      &__description {
        padding-top: 2px;
        font-weight: 700;
      }
    }

    @include breakpoint(mobile) {
      margin-bottom: 20px;
      padding: 0 !important;

      .box__link {
        box-shadow: 0 2px 4px 0 rgba(map-get($colors, black), 0.2);
      }

      &:last-child {
        margin-bottom: 50px;
      }
    }
  }

  &.-orange {
    .box {
      &__inner {
        &::after {
          background-color: rgba(map-get($colors, yellow-sea), 0.9);
        }
      }
    }
  }

  &.-black,
  &.-gray,
  &.-dark {
    .box {
      &__inner {
        &::after {
          background-color: rgba(map-get($colors, fiord), 0.9);
        }
      }
    }
  }

  &.-green {
    .box {
      &__inner {
        &::after {
          background-color: rgba(map-get($colors, green), 0.9);
        }
      }
    }
  }
}
