.intraHeader {
  @include breakpoint(tablet) {
    height: 82px !important;
  }

  & &__logout {
    @include breakpoint(tablet) {
      display: none;
    }
  }

  & &__menuInvoker {
    @include breakpoint(tablet) {
      margin-left: auto;
      padding-top: 23px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
}
