.intraFooter {
  display: block;
  width: 100%;
  text-align: center;

  & .grid__column > .grid__column {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  &__separator {
    margin: 0 auto 15px;
    width: 100%;
    height: 1px;
    background-color: map-get($colors, iron);
  }

  &__notice {
    display: block;
    margin-bottom: 30px;
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: map-get($colors, aluminium);
  }
}
