.navigation {
  flex-flow: row wrap;
  flex-grow: 1;
  display: flex;

  &__list {
    align-items: flex-end;
    padding-bottom: 25px;
    display: flex;
  }

  &__item {
    z-index: 1;
    margin-bottom: -30px;
    height: 50px;

    &:not(:last-child) {
      padding-right: 41px;
    }

    &:first-child {
      margin-right: 41px;
      padding-right: 0;
    }

    &.-children {
      &.-hover {
        & > a > span {
          position: relative;

          &::after {
            position: absolute;
            bottom: -25px;
            left: 50%;
            margin-left: -10px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 8px solid map-get($colors, blue-lagoon);
            content: '';
            opacity: 0;
            animation-name: fadeIn;
            animation-duration: 0;
            animation-delay: 0.1s;
            animation-fill-mode: forwards;
          }
        }

        .sub {
          z-index: 10;
          pointer-events: all;

          &__inner {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: 0.2s linear;
            transition-delay: 0.1s;
          }
        }
      }

      &.-hover .navigation__link {
        &::before {
          background-color: map-get($colors, blue-chill);
        }
      }
    }

    &:nth-last-child(2) {
      @include breakpoint(mobile up) {
        padding-right: 35px;
      }
    }
  }

  &__link {
    z-index: 1;
    position: relative;
    font-size: 1.6rem;
    line-height: 2rem;
    text-decoration: none;
    color: map-get($colors, white);

    .icon {
      position: relative;
      fill: map-get($colors, white);

      &.-caret-down {
        z-index: 1;
        top: -2px;
        margin-left: 2px;
        width: 8px;
        height: 5px;
      }

      &.-external-link {
        top: 2px;
        margin-left: 4px;
        width: 14px;
        height: 14px;
      }

      &.-star-o,
      &.-star {
        top: 2px;
        margin-left: 1px;
      }
    }

    &.-star {
      z-index: 1;
      position: relative;

      &::after {
        position: absolute;
        top: -1px;
        left: 0;
        width: 40px;
        height: 24px;
        content: '';
      }

      &::before {
        z-index: -1;
        position: absolute;
        top: -7px;
        right: -25px;
        bottom: -7px;
        left: -11px;
        content: '';
        transition: background-color 0.15s ease;
      }
    }

    &::before {
      z-index: -1;
      position: absolute;
      top: -7px;
      right: -11px;
      bottom: -7px;
      left: -11px;
      content: '';
      transition: background-color 0.15s ease;
    }

    &:hover {
      &::before {
        background-color: map-get($colors, eastern-blue-alt-2);
      }
    }
  }

  &__stars {
    position: relative;
    z-index: 5;
    margin-left: 4px;
    font-size: 1.6rem;
    line-height: 2rem;
    color: map-get($colors, white);
    pointer-events: none;
  }
}
