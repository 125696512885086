.material {
  @include reset-input;
  padding: 0;
  display: block;
  width: 100%;
  border-radius: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: map-get($colors, fiord);

  &:valid,
  &:focus,
  &:active {
    & + .label {
      margin-top: -20px;
      font-size: 1.2rem;
    }
  }

  &:focus,
  &:active {
    & + .label {
      color: map-get($colors, bondi-blue);
    }

    & ~ .input__border::after {
      opacity: 1;
      transform: scaleX(1);
      transition: 0.3s ease;
    }
  }

  @include breakpoint(mobile) {
    font-size: 1.4rem;
  }
}
