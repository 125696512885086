.section {
  width: 100%;

  &__title {
    padding-top: 60px;
    padding-bottom: 40px;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 3.3rem;
    text-transform: uppercase;
    text-align: center;
    color: map-get($colors, fiord);
  }

  &.-home {
    & + .section.-clothes {
      margin-top: 0;
    }

    &.-intro + .-home.-cards {
      padding-top: 0;
    }

    &.-cards {
      padding: 97px 0 70px;
    }
  }

  &.-rent,
  &.-category,
  &.-products,
  &.-product {
    @include breakpoint(tablet up) {
      padding-top: 78px;
      padding-bottom: 80px;
      min-height: 940px;
    }
  }

  &.-training {
    &:last-of-type {
      padding-top: 75px;
      padding-bottom: 8px;
    }
  }

  &.-training.-loop {
    @include breakpoint(mobile) {
      padding-top: 38px;
    }
  }

  &.-training-sub,
  &.-training-book {
    max-width: 640px;

    &:last-child {
      padding-bottom: 200px;

      @include breakpoint(mobile) {
        padding-bottom: 40px;
      }
    }
  }

  &.-contact {
    &.-top {
      padding-top: 78px;
      padding-bottom: 53px;

      @include breakpoint(mobile) {
        padding: 20px 0 0;
      }
    }

    &:last-child {
      padding-bottom: 200px;
    }
  }

  &.-department {
    padding-bottom: 60px;
    display: none;

    &.-visible {
      display: flex;
    }

    @include breakpoint(mobile) {
      &:last-child {
        padding-bottom: 50px !important;
      }
    }
  }

  &.-department.-contact {
    @include breakpoint(mobile) {
      padding: 0;
    }
  }

  &.-about {
    padding-top: 78px;
    padding-bottom: 200px;

    @include breakpoint(mobile) {
      padding-top: 0;
      padding-bottom: 50px;
    }
  }

  &.-booklets {
    @include breakpoint(mobile) {
      padding-bottom: 10px;
    }
  }

  &.-sortiment {
    &.-last {
      padding-bottom: 50px;

      @include breakpoint(tablet up) {
        padding: 0 20px;
      }
    }

    @include breakpoint(tablet up) {
      padding: 0 40px;
    }
  }

  &.-campaign.-home + .section.-cards {
    @include breakpoint(tablet) {
      padding-top: 0;
    }
  }
}
