.input {
  z-index: 1;
  position: relative;

  .label {
    position: absolute;
    top: -1px;
    left: 1px;
    font-size: 1.6rem;
    font-style: italic;
    line-height: 2.4rem;
    color: map-get($colors, fiord);
    pointer-events: none;
    transition: 0.3s ease;
    will-change: transform;
  }

  &__note {
    padding-top: 11px;
    display: block;
    text-align: right;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 1.5rem;
    color: map-get($colors, fiord);

    &.-book {
      padding-bottom: 50px;
    }

    @include breakpoint(mobile) {
      padding: 10px 0 47px;
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }

  &__border {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #e0e0e0;

    &::after {
      z-index: 1;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      content: '';
      opacity: 0;
      background-color: map-get($colors, bondi-blue);
      transform-origin: left;
      transform: scaleX(0);
      transition: opacity 0.3s ease, transform 0s 0.3s ease;
    }
  }

  &:not(:last-child) {
    .text {
      margin-bottom: 40px;

      @include breakpoint(mobile) {
        margin-bottom: 30px;
      }
    }
  }

  &.-book:last-of-type {
    .text {
      margin-bottom: 0;
    }
  }

  @include breakpoint(mobile) {
    .label {
      font-size: 1.4rem;
    }
  }
}
