body {
  font-family: 'Source Sans Pro', Helvetica, Arial, serif;
  background-color: map-get($colors, white);
}

input[type='text'],
input[type='email'] {
  @include breakpoint(mobile) {
    border-radius: 0;
  }
}

.grid__reset {
  &.-margin-35 {
    margin-right: -35px;
    margin-left: -35px;
  }
}

.grid {
  &.-pad {
    &,
    .grid__column {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

.spritesheet {
  display: none;
}

.text-center {
  text-align: center;
}

.-width-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.row-wrap {
  flex-flow: row wrap;
}

.column-wrap {
  flex-flow: column wrap;
}

.no-padding-width {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.no-padding-mobile {
  @include breakpoint(mobile) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.grid-reset-mobile {
  @include breakpoint(mobile) {
    margin: 0 -20px;
    width: auto;
  }
}

.padding {
  &-top {
    &-60 {
      padding-top: 60px;
    }

    &-90 {
      padding-top: 90px;
    }
  }
}

.margin {
  &-bottom {
    &-20 {
      margin-bottom: 20px;
    }
  }
}

.link-reset {
  text-decoration: none;

  &-white {
    color: map-get($colors, white);
  }
}

.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.hidden-mobile {
  @include breakpoint(mobile) {
    display: none !important;
  }
}

.hidden-tablet {
  @include breakpoint(tablet) {
    display: none !important;
  }
}

body.-loading {
  .js-content {
    opacity: 0.3;
  }
}

.js-content {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.hide-mobile {
  @include breakpoint(mobile) {
    display: none;
  }
}

.visible-mobile {
  display: none;

  @include breakpoint(mobile) {
    display: block;
  }
}

.productView {
  @media (min-width: 560px) and (max-width: 960px) {
    justify-content: space-between;
  }
}
