.training {
  width: 100%;

  @include breakpoint(mobile) {
    width: auto;
    margin: 0 -20px;
  }

  & p,
  & a {
    font-size: 1.4rem;
    line-height: 2.2rem;

    @include breakpoint(mobile up) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  & p {
    margin-bottom: 2.2rem;
    color: map-get($colors, mine-shaft);

    @include breakpoint(mobile up) {
      margin-bottom: 2.4rem;
    }
  }

  & a {
    text-decoration: none;
    color: map-get($colors, bondi-blue);
    transition: color 0.15s ease;

    &:hover {
      color: map-get($colors, blue-lagoon);
    }

    &:active {
      color: map-get($colors, blue-stone);
    }
  }

  & strong,
  & b {
    color: map-get($colors, fiord);
  }

  &__altContent {
    padding-top: 22px;

    @include breakpoint(mobile up) {
      padding-top: 24px;
    }
  }

  &__altContent b,
  &__altContent strong {
    display: block;
    margin-bottom: 5px;
  }
}
