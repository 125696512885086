.back {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: map-get($colors, gallery);

  &__link {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40px;
    background-color: map-get($colors, athens-gray);
    box-shadow: 1px 0 0 0 map-get($colors, white);

    .icon {
      position: relative;
      left: -1px;
      fill: map-get($colors, white);
      transform: rotate(-180deg);
    }
  }
}
