.newsletter {
  width: 250px;
  overflow: hidden;

  &__inner {
    z-index: 1;
    position: relative;
  }

  &__label,
  &__input {
    display: block;
    width: 100%;
    outline: none;
  }

  &__label {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: map-get($colors, aluminium);
  }

  &__input {
    margin-bottom: 12px;
    padding: 11px 13px;
    height: 40px;
    border: none;
    font-size: 1.4rem;
    line-height: 1.8rem;
    background-color: map-get($colors, wild-sand);

    &::placeholder {
      color: map-get($colors, aluminium);
    }
  }

  &__response {
    position: relative;
    top: -7px;
    display: none;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: map-get($colors, well-read);

    &.-red {
      color: map-get($colors, well-read);
    }

    &.-orange {
      color: map-get($colors, meteor);
    }

    &.-visible {
      display: block;
    }

    @include breakpoint(tablet) {
      top: -10px;
    }
  }

  &__button {
    width: 70px;
    height: 40px;
    border: 1px solid map-get($colors, bondi-blue);
    outline: none;
    border-radius: 3px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: map-get($colors, bondi-blue);
    background-color: map-get($colors, white);
    cursor: pointer;
    transition: opacity 0.2s ease;

    &.-loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__success {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    visibility: hidden;
    text-align: center;

    &.-visible {
      visibility: visible;
    }
  }

  &__message {
    text-align: center;

    & > span {
      margin-bottom: 6px;
      display: block;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: map-get($colors, mine-shaft);
    }
  }

  &__checkmark {
    z-index: 1;
    position: relative;
    margin: 0 auto 16px;
    width: 38px;
    height: 38px;
    border: 2px solid map-get($colors, green);
    border-radius: 50%;
    visibility: hidden;

    div {
      z-index: 1;
      top: 50%;
      left: 50%;
      position: absolute;
      margin-top: -1px;
      width: 16px;
      height: 9px;
      border-left: 2px solid map-get($colors, green);
      border-bottom: 2px solid map-get($colors, green);
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &.-active {
      animation: checkmark 0.3s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 0.4s;
    }
  }

  &__link {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4rem;
    text-decoration: none;
    color: map-get($colors, bondi-blue);
    transition: color 0.15s ease;

    &:hover {
      color: map-get($colors, blue-lagoon);
    }

    &:active {
      color: map-get($colors, blue-stone);
    }

    &::before {
      margin-right: 4px;
      content: '\00ab';
    }
  }

  @include breakpoint(mobile) {
    padding-bottom: 40px;
    width: 100%;
    border-bottom: 1px solid map-get($colors, very-light-gray);

    .newsletter__input {
      margin-bottom: 18px;
    }

    .newsletter__button {
      width: 110px;
    }
  }
}
