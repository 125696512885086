.sidebar {
  padding-right: 40px !important;
  padding-left: 0 !important;

  &__aside {
    width: 100%;
  }

  &__dropdown {
    height: 0;
    max-height: 1px;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    &.-active {
      height: auto;
      max-height: none;
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      transition: opacity 0.2s linear;
    }
  }

  &__nav,
  &__list {
    width: 100%;
  }

  &__list {
    flex-flow: column nowrap;
    display: flex;
  }

  &__item {
    align-items: center;
    display: flex;
    min-height: 50px;
    box-shadow: inset 0 -1px 0 0 map-get($colors, athens-gray);

    &.-active {
      &[data-level='3'] {
        box-shadow: inset 0 -2px 0 0 map-get($colors, bondi-blue);

        .sidebar__caption {
          font-weight: 600;
        }
      }

      &[data-level='1'],
      &[data-static] {
        box-shadow: inset 0 -1px 0 0 map-get($colors, bondi-blue);
      }
    }

    &.-active {
      .icon {
        transform: rotate(90deg);
      }

      &[data-level='2'] {
        background-color: map-get($colors, wild-sand);
        box-shadow: inset 0 -1px 0 0 map-get($colors, athens-gray);

        & + .sidebar__dropdown + & {
          position: relative;
          z-index: 2;
          //box-shadow: 0 -1px 0 0 map-get($colors, white), inset 0 1px 0 0 map-get($colors, white);

          &::before {
            position: absolute;
            top: -1px;
            right: 0;
            left: 0;
            width: 100%;
            height: 1px;
            content: '';
            background: map-get($colors, white);
          }
        }
      }
    }

    &[data-level='3'] {
      position: relative;
      padding-left: 30px;

      .sidebar__caption {
        color: map-get($colors, blue-lagoon);
      }

      &::after {
        position: absolute;
        bottom: -3px;
        left: -12px;
        width: 38px + 12px;
        height: 6px;
        content: '';
        background-color: map-get($colors, white);
      }
    }
  }

  &__link {
    align-items: center;
    flex-flow: row wrap;
    padding: 0 15px 0 12px;
    display: flex;
    width: 100%;
    height: 100%;
    outline: none;
    text-decoration: none;

    .icon {
      position: relative;
      top: 2px;
      margin-left: auto;
      display: block;
      width: 5px;
      height: 9px;
      fill: map-get($colors, fiord);
    }
  }

  &__caption {
    padding-top: 15px;
    padding-bottom: 15px;
    width: calc(100% - 5px);
    color: map-get($colors, fiord);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    pointer-events: none;

    &[data-level='1'],
    &[data-level='2'] {
      font-weight: 400;
    }

    &[data-level='1'] {
      padding-left: 10px;
      color: map-get($colors, fiord);
    }
  }

  &.-mobile {
    margin: 0 -20px 40px;
    padding-right: 0 !important;
    width: 100vw;
    display: none;

    .sidebar__nav > .sidebar__list:first-child {
      box-shadow: inset 0 1px 0 0 map-get($colors, athens-gray);
    }

    .sidebar__link {
      padding: 0 25px;
    }

    .sidebar__item::after {
      display: none;
    }

    @include breakpoint(mobile) {
      display: block;
    }
  }

  @include breakpoint(mobile) {
    display: none;
  }

  &.-booklets.-mobile {
    padding-top: 50px;
  }
}
