.links {
  padding-right: 155px;

  &__title,
  &__link {
    font-size: 1.4rem;
  }

  &__title {
    display: block;
    margin-bottom: 7px;
    line-height: 1.8rem;
    color: map-get($colors, aluminium);
  }

  &__link {
    text-decoration: none;
    line-height: 2.2rem;
    color: map-get($colors, bondi-blue);
    transition: color 0.15s ease;

    &:hover {
      color: map-get($colors, blue-lagoon);
    }

    &:active {
      color: map-get($colors, blue-stone);
    }
  }

  &__list {
    padding: 0;
  }

  @include breakpoint(mobile) {
    padding: 40px 0;
    width: 100%;
    border-bottom: 1px solid map-get($colors, very-light-gray);

    .links__title {
      margin-bottom: 0;
      line-height: 2rem;
    }

    .links__link {
      font-weight: 600;
      line-height: 2rem;
    }
  }
}
