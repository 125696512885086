.dropslide {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;

  &__container {
    flex-flow: column wrap;
    display: flex;
    width: 100%;
  }

  &__slide {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-top: 60px;
    flex-flow: column wrap;
    display: flex;
    overflow-y: scroll;
    -ms-overflow-style: none;

    .mobileNav__item {
      width: 100%;
    }

    & > .mobileNav__item:last-child {
      margin-bottom: 40px;
    }

    &.-right {
      transform: translateX(100%);
    }

    &.-left {
      transform: translateX(-100%);
    }
  }

  &.-visible {
    height: auto;
    visibility: visible;
    opacity: 1;

    & ~ .mobileNav__item {
      display: none;
    }
  }
}
