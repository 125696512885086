.contactInfo {
  margin-top: 11px;
  padding: 20px 30px 25px;
  float: right;
  max-width: 464px;
  background-color: map-get($colors, gallery);

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: map-get($colors, mine-shaft);

    a {
      color: map-get($colors, bondi-blue);
      transition: color 0.15s ease;

      &:hover {
        color: map-get($colors, blue-lagoon);
      }

      &:active {
        color: map-get($colors, blue-stone);
      }

      &.phoneLink {
        color: map-get($colors, mine-shaft);
        text-decoration: none;
        transition: all 0.15s ease;

        &:hover {
          color: map-get($colors, blue-lagoon);          
        }

        &:active {
          color: map-get($colors, blue-stone);
        }        
      }
    }
  }

  strong {
    text-transform: uppercase;
  }

  .grid__column {
    padding-right: 0 !important;
    padding-left: 0 !important;

    @include breakpoint(mobile up) {
      &:first-child,
      &:nth-child(2) {
        padding-bottom: 25px;
      }
    }
  }

  @include breakpoint(mobile) {
    float: left;
    margin: 0 0 48px;
    padding: 42px 0 0;
    background-color: transparent;

    & > div:not(:last-child) {
      margin-bottom: 23px;
    }
  }

  @media (min-width: 960px) and (max-width: 1070px) {
    height: 299px;
  }
}
