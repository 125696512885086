.-clothes {
  justify-content: center;
  align-items: center;
  padding: 70px 20px;
  margin-top: 42px;
  background-image: url(../images/clothes.jpg);
  background-size: cover;
  background-position: center top;

  @include breakpoint(mobile) {
    padding: 40px 20px;
  }
}

.clothes {
  padding: 45px 50px;
  @include breakpoint(mobile) {
    padding: 22.5px 50px;
  }
}

.clothes.box {
  flex-basis: 463px;
  justify-content: center;
  margin: 20px;
  max-width: 463px;
  background-color: rgba(232,162,56,0.9);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

  .box__inner {
    padding-top: 0;
  }

  .box__title {
    @include breakpoint(mobile) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .box__description,
  .box__title::after {
    @include breakpoint(mobile) {
      display: none;
    }
  }
}

.clothlogo {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;

  &__img {
    flex-grow: 1;
    max-width: 85px;
    padding: 5px;
    margin-right: 15px;
    margin-bottom: 10px;
  }

  @include breakpoint(mobile) {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.headInfo__image {
  &.-clotheshead {
    margin-bottom: 30px;
  }
}
