.intraPage {
  padding-top: 95px;
  padding-bottom: 60px;

  @include breakpoint(tablet) {
    padding-top: 40px;
  }

  @include breakpoint(560px) {
    padding-top: 27px;
    padding-bottom: 36px;
  }
}
