.intraLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: map-get($colors, bondi-blue);

  & * {
    font-family: $font-family;
  }

  & .formfield {
    display: block;
  }

  & .formfield:last-of-type {
    padding-top: 70px;
  }

  &__grid {
    max-width: 340px;
    text-align: center;

    @include breakpoint(tablet down) {
      padding: 0 22px;
    }
  }

  &__figure {
    margin-bottom: 60px;
  }

  &__figure a {
    display: inline-block;
    text-decoration: none;
  }

  &__figure .icon {
    width: 200px;
    height: 63px;
    pointer-events: all;
  }

  &__textField {
    z-index: 1;
    position: relative;
  }

  &__textField figure {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 31px;
  }

  &__textField .icon {
    color: map-get($colors, bondi-blue);
  }

  &__textField .icon {
    width: 14px;
    height: 14px;
  }

  &__textField input {
    appearance: none;
    margin-bottom: 12px;
    padding: 15px 15px 15px 49px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 1px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    color: map-get($colors, mine-shaft);
    background-color: map-get($colors, gallery);

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 100px map-get($colors, gallery);
    }
  }

  &__textField input::placeholder {
    font-style: italic;
    color: map-get($colors, mine-shaft);
    opacity: 1;
  }

  &__half {
    float: left;
    width: 50%;
    text-align: left;
  }

  &__half + &__half {
    text-align: right;
  }

  &__checkbox {
    @include reset-input;
    margin-right: 5px;
    padding: 4px;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    border-radius: 3px;
    background-color: map-get($colors, white);
    background-size: auto 12px;
    background-repeat: no-repeat;
    background-position: center 65%;
  }

  &__checkbox:checked {
    @include checkmark(map-get($colors, bondi-blue));
  }

  &__span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: map-get($colors, white);
    text-decoration: none;
  }

  a.intraLogin__span:hover {
    text-decoration: underline;
  }

  &__error {
    padding-bottom: 15px;
  }

  &__error span {
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: map-get($colors, white);
  }

  &__button {
    @include reset-button;
    padding: 15px 65px;
    display: inline-block;
    border: 1px solid map-get($colors, white);
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-transform: uppercase;
    color: map-get($colors, white);
    transition: background-color 0.15s ease-out;
    will-change: background-color;
  }

  &__button:hover {
    background-color: rgba(map-get($colors, white), 0.08);
  }

  &__button:active {
    background-color: darken(map-get($colors, bondi-blue), 2%);
  }
}
