.logo {
  position: relative;
  display: inline-block;
  width: 172px;
  height: 50px;

  .icon {
    width: 172px;
    height: 50px;
    pointer-events: all;
  }

  @include breakpoint(mobile) {
    margin: 0 auto;
    padding-top: 40px;

    &,
    .icon {
      width: 141px;
      height: 40px;
    }
  }
}
