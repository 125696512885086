@mixin checkmark($color: #3C3C3C) {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22#{$color}%22%20d%3D%22M16%2C2.39483871%20C16%2C2.67010753%2015.9036559%2C2.90408602%2015.7109677%2C3.09677419%20L8.23741935%2C10.5703226%20L6.83354839%2C11.9741935%20C6.64086021%2C12.1668818%206.40688172%2C12.2632258%206.1316129%2C12.2632258%20C5.85634408%2C12.2632258%205.62236559%2C12.1668818%205.42967742%2C11.9741935%20L4.02580645%2C10.5703226%20L0.289032258%2C6.83354839%20C0.096344086%2C6.64086021%200%2C6.40688172%200%2C6.1316129%20C0%2C5.85634408%200.096344086%2C5.62236559%200.289032258%2C5.42967742%20L1.69290323%2C4.02580645%20C1.8855914%2C3.83311828%202.11956989%2C3.73677419%202.39483871%2C3.73677419%20C2.67010753%2C3.73677419%202.90408602%2C3.83311828%203.09677419%2C4.02580645%20L6.1316129%2C7.07096774%20L12.9032258%2C0.289032258%20C13.095914%2C0.096344086%2013.3298924%2C0%2013.6051613%2C0%20C13.8804301%2C0%2014.1144086%2C0.096344086%2014.3070968%2C0.289032258%20L15.7109677%2C1.69290323%20C15.9036559%2C1.8855914%2016%2C2.11956989%2016%2C2.39483871%20Z%22%20transform%3D%22translate%280%201%29%22%2F%3E%3C%2Fsvg%3E');
}

// Clearfix
@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
    flex-basis: 0;
  }

  &::after {
    clear: both;
  }
}

// Breakpoint
@mixin breakpoint($value) {
  $bp: nth($value, 1);
  $dir: if(length($value) > 1, nth($value, 2), down);
  $media-query: if($dir == 'down', max-width, min-width);

  @if type-of($bp) == 'number' and unit($bp) == 'px' {
    @media only screen and (#{$media-query}: $bp) {
      @content;
    }
  }
  @else if map-has-key($tweakpoints, $bp) {
    @media only screen and (#{$media-query}: map-get($tweakpoints, $bp)) {
      @content;
    }
  }
  @else if map-has-key($breakpoints, $bp) {
    @media only screen and (#{$media-query}: map-get($breakpoints, $bp)) {
      @content;
    }
  }
  @else {
    @warn 'Could not find point in $tweakpoints or $breakpoints.';
  }
}

// Grid column
@mixin column($value: $grid-columns) {
  @extend .grid__column;
  $val: nth($value, 1);
  $columnWidth: if(length($value) > 2, 100 / nth($value, 3), $grid-columns-width);

  @if unitless($val) {
    width: #{$columnWidth * $val * 1%};
  }
  @else if type-of($val) == 'number' and unit($val) == '%' {
    width: $val;
  }
  @else {
    @warn 'Invalid value passed to grid-column mixin.';
  }
}

// Grid column offset
@mixin column-offset($value: 1) {
  $val: nth($value, 1);
  $columnWidth: if(length($value) > 2, 100 / nth($value, 3), $grid-columns-width);
  margin-left: #{$columnWidth * $val * 1%};
}

// Reset input
@mixin reset-button {
  display: inline-block;
  vertical-align: top;
  outline: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-family: $font-family;
  line-height: 1em;
  text-decoration: none;
  text-align: center;
  appearance: none;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

// Reset input
@mixin reset-input {
  border: 0;
  border-radius: 0;
  font-family: $font-family;
  line-height: 1em;
  appearance: none;
  background: transparent;

  &:focus {
    outline: none;
  }

  @at-root textarea#{&} {
    resize: vertical;
  }
}
