// Breakpoints
$breakpoints: (
  desktop: 1200px,
  tablet: 960px,
  mobile: 960px
  // mobile: 560px
);

// Tweakpoints
$tweakpoints: ();

// Grid
$grid-width: 100%;
$grid-max-width: 1170px + 40px;
$grid-columns: 12;
$grid-columns-width: (100 / $grid-columns) !default;

$grid-gutter: (
  desktop: 15px,
  tablet: 15px,
  mobile: 10px
);

$grid-column-gutter: (
  desktop: 15px,
  tablet: 10px,
  mobile: 5px
);

// Fonts
$font-family: 'Source Sans Pro', Arial, sans-serif;
$font-family-alt: Georgia, serif;

// Easings
$easings: (
  swiftOut: cubic-bezier(0.55, 0, 0.1, 1),
  appleEase: cubic-bezier(0.4, 0.01, 0.165, 0.99),
  appleEaseAlt: cubic-bezier(0.28, 0.11, 0.32, 1)
);

// Colors
$colors: (
  black: #000000,
  white: #ffffff,
  green: #26ab65,
  bondi-blue: #009aa9,
  gallery: #efefef,
  very-light-gray: #cecece,
  armadillo: #4a4a4a,
  eastern-blue: #1ea4b1,
  eastern-blue-alt: #0093a1,
  eastern-blue-alt-2: #008d99,
  abbey: #4a4e56,
  aluminium: #9b9b9b,
  aluminium-alt: #979797,
  concrete: #d0d0d0,
  iron: #d8d8d8,
  wild-sand: #f4f4f4,
  blue-lagoon: #007f8a,
  blue-lagoon-alt: #02656e,
  athens-gray: #dfdfdf,
  mine-shaft: #363b44,
  yellow-sea: #f7a600,
  fiord: #505865,
  bombay: #afafaf,
  carmine-pink: #e83e4b,
  bastille: #292d34,
  gamboge: #eb9e00,
  pirate-gold: #c18200,
  bleach-white: #fef2d9,
  harvest-gold: #d79101,
  well-read: #b03130,
  meteor: #d97f0f,
  glacier: #76b5bb,
  blue-stone: #005a62,
  boulder: #7a7a7a,
  storm-dust: #646464,
  ecalyptus: #1e8d53,
  jewel: #176a3e,
  blue-chill: #0e8c98,
  gray: #919191,
  white-smoke: #f5f5f5,
  silver: #c0c0c0,
);
