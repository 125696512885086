.starred {
  &__list,
  &__item,
  &__link,
  &__remove {
    display: flex;
  }

  &__list {
    flex-flow: column wrap;
    display: flex;
    width: 100%;
  }

  &__error {
    font-style: italic;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: map-get($colors, fiord);
  }

  &__item {
    width: 100%;
    height: 60px;
    box-shadow: inset 0 -1px 0 0 map-get($colors, athens-gray);
    transition:
      opacity 0.1s ease,
      transform 0.3s ease;

    &:first-of-type {
      box-shadow:
        inset 0 1px 0 0 map-get($colors, athens-gray),
        inset 0 -1px 0 0 map-get($colors, athens-gray);
    }

    &.-remove {
      opacity: 0;
      transform: translateY(-90%);
    }

    &.-hidden {
      display: none;
    }
  }

  &__link,
  &__remove {
    text-decoration: none;
  }

  &__link {
    align-items: center;
  }

  &__icon {
    padding: 0 15px 0 11px;

    .icon {
      width: 17px;
      height: 17px;
    }
  }

  &__text {
    * {
      display: block;
      width: 100%;
    }

    strong {
      font-size: 1.6rem;
      line-height: 2rem;
      color: map-get($colors, mine-shaft);
    }

    small {
      font-size: 1.2rem;
      font-style: italic;
      line-height: 1.5rem;
      color: map-get($colors, aluminium);
    }
  }

  &__remove {
    z-index: 1;
    position: relative;
    align-items: center;
    margin-left: auto;
    padding-right: 27px;

    &::after {
      position: absolute;
      left: -5px;
      width: 20px;
      height: 20px;
      content: '';
    }
  }

  &.-list {
    margin: 0 auto;
    width: 100%;
    max-width: 570px;

    .starred__item {
      padding: 13px 0 11px;
      min-height: 60px;
      height: auto;
    }

    @include breakpoint(mobile) {
      .starred__remove {
        padding-right: 13px;
      }
    }
  }
}

.starredContacts {
  margin: 0 auto;
  padding-top: 40px;
  max-width: 570px;

  &__text {
    padding-bottom: 40px;

    p {
      display: block;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: map-get($colors, mine-shaft);
    }
  }

  .card {
    margin-bottom: 20px;
  }

  @include breakpoint(mobile) {
    .card {
      margin-bottom: 10px;
    }
  }

  @include breakpoint(mobile) {
    .starredContacts__text p {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
}
